import { IconSignOut } from "./Icons";

type TProps = {
  action: () => void;
};

export default function SignOutButton({ action }: TProps) {
  return (
    <button
      onClick={action}
      className="text-white bg-transparent mt-2 hover:bg-[#529A98] rounded text-sm w-8 h-8 flex justify-center items-center"
    >
      <IconSignOut />
    </button>
  );
}

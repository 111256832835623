import { useCallback } from "react";

export default function BodySvgBack({
  onPartClick,
  selected,
  selectedAvoid,
}: any) {
  const makeClickHandler = (name: any) => (e: any) => onPartClick(name);

  const verifySelected = useCallback(
    (id: string) => {
      if (selectedAvoid.includes(id)) {
        return "#fca5a5";
      } else if (selected.includes(id)) {
        return "#529A98";
      }

      return "#b4c8c8";
    },
    [selected, selectedAvoid]
  );

  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      width="82px"
      // height="80px"
      viewBox="1200 0 2000 6910"
    >
      <defs id="defs123" />

      <path
        fill="#469790"
        opacity="1"
        stroke="none"
        d="m 1675.6121,1204.3617 c 57.8497,-22.0304 115.78,-43.8723 171.078,-71.9649 37.4913,-19.0464 73.3353,-40.6401 106.3863,-66.8451 58.8085,-46.6271 95.4889,-106.53187 109.7848,-180.18195 0.8871,-4.57013 1.8271,-9.13226 2.8632,-13.67047 0.8843,-3.87335 1.9423,-7.70697 3.1906,-12.60669 4.3203,-0.54523 8.7499,-1.58905 13.1801,-1.59259 113.2699,-0.0901 226.54,-0.0826 339.8099,-0.032 4.5824,0.002 9.1647,0.59118 14.3321,0.94702 4.3824,8.01928 5.5977,16.50244 7.2229,24.86761 8.4799,43.64948 23.5439,84.58161 50.033,120.76847 21.0492,28.7553 46.6527,52.8531 75.3383,73.8424 43.2371,31.6369 90.3695,56.3918 139.2584,77.7741 33.5248,14.6627 67.7975,27.6092 101.605,41.6382 4.6215,1.9178 10.8007,1.6554 11.4803,8.9723 -185.7778,1.4926 -369.7327,1.5393 -553.6878,1.5271 -5.3012,-4e-4 -10.6076,-0.5597 -15.9012,-0.9861 -2.5512,-0.2056 -5.0995,-1.1297 -7.6129,-1.0041 -10.5727,0.5289 -21.1312,1.9347 -31.6958,1.9427 -170.6252,0.1275 -341.2504,0.091 -511.8757,0.084 -5.9977,-2e-4 -12.0207,0.221 -17.9804,-0.2771 -2.3426,-0.1958 -4.5436,-2.0852 -6.8091,-3.2032 z"
        id="B-neck"
        // onClick={makeClickHandler("B-neck")}
        style={{ fill: verifySelected("B-neck") }}
      />
      <path
        fill="#46968f"
        opacity="1"
        stroke="none"
        d="m 2281.5447,36.242984 c 4.8653,1.171399 7.7756,3.010491 10.8739,3.417559 105.7544,13.89433 186.8578,66.544657 243.8136,156.205027 30.4443,47.92587 48.1268,100.82376 55.3191,157.04913 2.6988,21.09793 3.4875,42.44156 5.0872,63.67768 0.3501,4.64691 0.3073,9.32425 0.6856,13.96801 0.847,10.39575 4.625,19.3295 13.1089,25.94065 2.618,2.04013 4.8943,4.51745 7.3365,6.78439 26.2602,24.37555 31.5531,54.53806 23.4694,87.90182 -4.7555,19.62763 -14.124,37.43818 -25.755,53.80243 -9.633,13.5531 -19.6895,26.9679 -30.9287,39.18323 -25.0201,27.19287 -45.5192,57.1629 -62.0358,90.25018 -20.9132,41.89527 -47.9919,79.83814 -76.8679,114.90583 -1.8938,0.97992 -2.47,1.32342 -3.0822,1.58209 -0.603,0.25464 -1.2423,0.60339 -1.8659,0.60431 -121.3057,0.16919 -242.6114,0.31128 -363.917,0.45325 -4.6379,0.005 -9.2757,7.3e-4 -14.4023,7.3e-4 -13.6406,-15.87708 -26.491,-32.03278 -37.3629,-49.4776 -16.9006,-27.11872 -32.742,-54.89948 -48.9208,-82.46515 -4.0441,-6.8905 -7.7059,-14.00781 -11.4658,-21.0622 -7.2669,-13.63372 -16.3582,-25.68109 -27.9112,-36.16778 -17.3288,-15.72949 -32.1432,-33.79419 -45.1463,-53.263 -11.5421,-17.28162 -20.4994,-35.77698 -25.8383,-55.98413 -4.1708,-15.78672 -4.3839,-31.78061 -1.5658,-47.57123 2.6179,-14.66934 9.6037,-27.6517 20.5227,-38.13941 7.1969,-6.91262 14.5887,-13.62228 22.7374,-21.20871 1.2549,-17.24655 2.5417,-35.13897 3.8614,-53.02893 4.9123,-66.59121 20.5162,-130.23628 54.1391,-188.45289 52.0746,-90.16455 129.6385,-144.756847 232.0326,-163.908573 6.5294,-1.221247 13.1715,-1.839591 20.8535,-3.867561 21.1808,-1.129156 41.2692,-1.129156 63.225,-1.129152 z"
        id="B-head"
        // onClick={makeClickHandler("B-head")}
        style={{ fill: verifySelected("B-head") }}
      />
      <path
        fill="#46968f"
        opacity="1"
        stroke="none"
        d="m 2264.0702,3683.3865 c -0.7152,-44.7923 -1.4099,-89.585 -2.1882,-134.3763 -0.057,-3.2668 -0.7198,-6.5229 -2.4428,-12.4621 -6.2817,-3.8452 -11.5003,-6.3455 -14.8377,0.1457 -0.3131,13.9629 -0.7649,27.9241 -0.9088,41.8887 -0.2539,24.6433 -0.32,49.2881 -0.8981,76.4919 -0.059,4.7586 0.3147,6.9576 0.6888,9.1568 -0.2782,5.3225 -0.5564,10.645 -2.4864,18.2659 -9.6063,2.7075 -17.5608,3.4709 -25.5154,3.4738 -179.2743,0.068 -358.5485,0.052 -537.8227,0.047 -5.9974,-3e-4 -11.9975,0.035 -17.9907,-0.1389 -2.447,-0.071 -5.463,0.1347 -5.235,-4.3411 1.9354,-1.2627 4.0205,-2.8447 6.2964,-4.0732 23.25,-12.5537 42.2148,-29.9109 58.403,-50.7002 21.5347,-27.6546 37.0826,-58.3855 48.4069,-91.3728 26.5498,-77.3374 29.7307,-156.667 18.4348,-236.8203 -10.4981,-74.4917 -34.2176,-145.0418 -68.1468,-212.0823 -10.8138,-21.367 -23.307,-41.8799 -34.8113,-62.9041 -2.3917,-4.3708 -7.2207,-8.1687 -4.7751,-14.3935 33.9486,-1.4524 67.8353,-3.0318 101.7332,-4.3186 38.5976,-1.4656 77.2022,-2.7649 115.8091,-3.9637 46.5979,-1.447 93.1949,-3.0319 139.8041,-3.9746 55.9538,-1.1316 111.9189,-2.1777 167.8811,-2.2939 64.6296,-0.1343 129.2612,0.8257 193.8925,1.2253 21.9796,0.1358 43.9609,0.012 67.7198,0.316 17.0603,0.9213 32.3386,1.6428 47.6245,2.124 44.5497,1.4026 89.105,2.6306 133.6534,4.073 53.8576,1.7436 107.7115,3.6089 161.5633,5.5273 3.6641,0.1304 8.091,-1.3042 11.1373,4.6111 -4.7264,6.9741 -9.8106,14.561 -14.9797,22.0896 -40.1265,58.4443 -73.6128,120.2744 -95.0336,188.1304 -21.3036,67.4841 -30.1113,136.1521 -18.2126,206.6098 16.3238,96.6607 64.1151,173.637 144.4656,229.9759 11.3487,7.957 24.0201,14.0278 35.4364,22.6528 -6.4657,2.469 -12.288,3.9421 -18.1114,3.9458 -189.6644,0.1204 -379.3288,0.1257 -568.9933,-0.011 -6.5208,0 -13.0404,-1.6458 -19.5606,-2.5244 z"
        id="B-avoid1"
        style={{ fill: "#fca5a5" }}
      />
      <path
        fill="#479790"
        opacity="1"
        stroke="none"
        d="m 2838.8569,3009.082 c 6.4094,41.4456 13.0457,82.8574 19.1642,124.346 6.1189,41.4912 11.7704,83.0517 17.5322,124.5952 4.9388,35.6074 10.0358,71.1963 14.5174,106.862 3.6518,29.0642 6.2112,58.2644 9.7602,87.3428 7.0585,57.8308 3.9682,115.7927 2.5731,173.7232 -0.4163,17.2985 -2.1064,34.5739 -3.5578,51.8308 -0.1753,2.0854 -2.5139,3.9895 -6.3759,5.0466 -41.2186,-19.7158 -75.7345,-44.0823 -105.5599,-75.3482 -41.9769,-44.0036 -69.531,-95.6623 -83.3059,-154.5561 -14.669,-62.7178 -12.3389,-125.4731 2.2162,-187.988 15.0963,-64.8401 41.7238,-124.8785 76.3637,-181.409 14.2467,-23.2497 30.2421,-45.4267 45.3879,-68.1274 2.7302,-4.0921 5.7551,-7.3653 11.2846,-6.3179 z"
        id="BR-sciatic"
        // onClick={makeClickHandler("BR-sciatic")}
        style={{ fill: verifySelected("BR-sciatic") }}
      />
      <path
        fill="#46968f"
        opacity="1"
        stroke="none"
        d="m 2895.3939,3694.2568 c -0.3979,15.2478 0.031,30.5718 -1.3634,45.728 -3.0467,33.1246 -6.6355,66.2117 -10.7053,99.2273 -5.8601,47.5374 -11.8098,95.0979 -23.8803,141.5865 -2.8071,10.8115 -4.6262,22.4309 -16.9232,29.2314 5.2315,8.7395 4.4611,17.6929 2.542,26.9353 -10.816,52.0901 -20.7163,104.3792 -32.1899,156.3215 -24.8211,112.3667 -50.7312,224.4927 -75.555,336.8589 -14.4929,65.6031 -27.1434,131.6118 -36.88,198.0982 -6.2655,42.7837 -10.8974,85.7729 -14.0968,128.9643 -3.8458,51.9209 -3.1145,103.8159 -4.6827,157.9751 -37.6824,0.2793 -73.5577,-1.5473 -109.4177,-3.6347 -57.099,-3.3228 -114.1713,-7.1328 -171.2864,-10.1368 -40.5067,-2.1303 -81.0653,-3.2714 -121.6006,-4.8549 -3.4205,-47.1602 -7.4581,-94.2871 -10.0166,-141.4937 -1.4039,-25.9028 -1.1277,-51.9722 -0.1295,-77.9097 1.1006,-28.5996 3.5143,-57.1689 6.0494,-85.6894 3.2417,-36.4702 7.3065,-72.8672 10.773,-109.3189 2.9621,-31.1469 6.571,-62.1948 5.7633,-93.6611 -2.1721,-84.6064 -3.0437,-169.2475 -5.3678,-253.8486 -1.9211,-69.9278 -5.0647,-139.8233 -7.8772,-209.7241 -2.4907,-61.9046 -5.388,-123.7925 -7.8858,-185.6966 -1.8804,-46.602 -3.4127,-93.218 -3.2152,-141.906 201.9512,-2.1101 402.0175,-2.1316 602.0839,-2.2117 8.6207,0 17.2412,-0.5478 25.8618,-0.8403 z"
        id="BR-leg"
        // onClick={makeClickHandler("BR-leg")}
        style={{ fill: verifySelected("BR-leg") }}
      />
      <path
        fill="#45968f"
        opacity="1"
        stroke="none"
        d="m 2775.2996,2094.8984 c -6.3105,50.6956 -12.6548,101.3867 -18.9003,152.0903 -1.1333,9.201 -1.8615,18.4517 -5.5608,28.9646 -19.9132,-1.0231 -37.0499,-3.2766 -54.1699,-5.6513 -32.927,-4.5672 -65.8116,-9.4563 -98.7747,-13.7432 -37.5796,-4.8869 -75.1759,-9.7329 -112.8569,-13.729 -38.3477,-4.0666 -76.7575,-7.8594 -115.2329,-10.3215 -35.1601,-2.25 -70.4464,-2.5027 -105.6707,-3.8091 -3.6944,-0.1372 -7.3452,-1.4465 -11.9808,-2.4133 0,-6.9417 0.01,-12.8636 0,-18.7857 -0.1408,-73.2043 -0.3253,-146.4089 -0.4144,-219.6134 -0.067,-55.2384 0.019,-110.477 -0.052,-165.7156 -0.01,-7.3 -0.5456,-14.5994 -0.6655,-23.7691 0.03,-113.8101 -0.1473,-225.7503 -0.2439,-337.6903 -0.064,-73.9634 -0.06,-147.927 0.065,-221.8902 0.012,-7.0835 -1.4574,-14.5236 1.7796,-20.665 2.3116,-0.8711 3.5182,-1.723 4.7257,-1.7242 179.9029,-0.1922 359.8058,-0.3263 539.7087,-0.4347 4.6517,0 9.3048,0.2818 13.9541,0.5003 0.6301,0.03 1.2998,0.3697 1.8445,0.7281 0.4853,0.3194 0.823,0.8634 2.1226,2.2984 -0.8776,3.026 -1.9694,6.7233 -3.0221,10.4317 -3.8165,13.4442 -8.3474,26.7348 -11.2935,40.3678 -9.2979,43.0236 -14.2639,86.6763 -17.5139,130.5328 -2.0665,27.8851 -4.6217,55.7543 -5.8061,83.6793 -1.2403,29.2509 -1.1034,58.5602 -1.8192,89.6943 -0.4524,80.6702 -0.7472,159.4897 -0.7932,238.3093 -0.012,21.1788 0.7312,42.358 0.8119,65.3995 -0.2138,19.7278 -0.4915,37.6051 0.068,55.456 1.2832,40.9686 2.9747,81.924 4.3141,124.653 -0.3245,11.2482 -0.4626,20.7281 -0.5476,30.1842 0.053,-0.024 0.092,-0.1335 -1.2148,0.3796 -1.8307,2.2601 -2.3543,4.0069 -2.8829,5.8902 -0.01,0.1365 0.024,0.3962 0.024,0.3962 z"
        id="BR-shoulder-blade"
        // onClick={makeClickHandler("BR-shoulder-blade")}
        style={{
          fill: verifySelected("BR-shoulder-blade"),
        }}
      />
      <path
        fill="#46968f"
        opacity="1"
        stroke="none"
        d="m 2667.4895,5024.0891 c 4.5487,0.6328 9.0974,1.2656 13.6282,1.896 0.9394,2.31 2.1932,4.0747 2.3233,5.9184 1.2188,17.2535 2.1718,34.5249 3.342,51.7823 2.5203,37.1694 6.0219,74.2988 7.4571,111.5088 1.6677,43.2324 1.7039,86.5317 2.1389,129.8056 0.174,17.3052 -0.4869,34.6197 -0.7514,51.9297 -1.0706,70.022 -8.0572,139.4028 -22.1089,208.0571 -5.0658,24.75 -9.3955,49.6504 -14.2715,74.441 -1.661,8.4458 -3.3236,16.915 -8.3725,24.3388 -1.7358,2.5523 -2.7619,6.1177 -2.7778,9.2266 -0.1465,28.9224 -8.1473,56.5708 -13.8269,84.5347 -24.3503,119.8955 -49.5957,239.6098 -73.6503,359.5634 -10.4586,52.1548 -18.5372,104.7744 -24.1192,157.7212 -0.6269,5.9473 -0.5965,11.9634 -1.4348,20.3667 -0.2323,4.6299 0.099,6.8404 0.4311,9.0508 -2.6953,51.1309 -0.8822,102.2075 0.036,155.1348 -40.1153,-0.5772 -78.4067,-3.3643 -116.7402,-5.3228 -23.7804,-1.2153 -47.6248,-1.1733 -71.4406,-1.6953 -16.3739,-2.2988 -33.2675,1.1904 -49.2368,-3.8359 -4.0437,-6.6392 -2.4202,-13.4287 -2.3522,-19.8745 0.7096,-67.274 1.9911,-134.5435 2.3529,-201.8184 0.3943,-73.2817 0.4075,-146.5718 -0.2008,-219.8516 -0.5473,-65.9399 -1.7036,-131.8833 -3.507,-197.8003 -0.8366,-30.5844 -3.831,-61.1113 -5.8777,-91.6621 -1.4685,-21.9189 -2.992,-43.8335 -4.4833,-65.7509 -3.1182,-45.8296 -6.0876,-91.669 -9.4057,-137.4839 -1.7303,-23.8926 -4.5499,-47.7129 -5.9724,-71.6192 -0.987,-16.5849 -1.3275,-33.313 -0.382,-49.8872 2.1605,-37.875 5.292,-75.6943 8.0208,-113.5371 4.4568,-61.8057 6.2897,-123.7143 6.1101,-185.6558 -0.098,-33.9545 -1.8401,-67.9052 -1.1278,-104.269 4.3619,-2.6274 7.0193,-3.1064 9.6582,-3.02 37.8822,1.2436 75.7929,1.979 113.6356,3.9888 75.0381,3.9843 150.0345,8.75 225.0581,13.0185 12.5902,0.7163 25.2302,0.5557 37.8473,0.8008 z"
        id="BR-calf"
        // onClick={makeClickHandler("BR-calf")}
        style={{ fill: verifySelected("BR-calf") }}
      />
      <path
        fill="#469790"
        opacity="1"
        stroke="none"
        d="m 2777.1468,2095.258 c -1.8472,-0.3596 -1.8767,-0.6193 0.015,-0.7002 4.1591,-2.0693 4.3109,-4.1945 2.3031,-6.4589 0,0 -0.039,0.1096 0.5096,1.7099 6.6393,3.8538 12.3184,1.9321 17.8985,-0.2644 9.8603,-3.8811 19.7833,-7.6372 29.4676,-11.9253 40.2478,-17.8213 76.6071,-42.0588 112.0172,-67.9179 32.1203,-23.4561 64.3967,-46.706 96.9256,-69.59 9.7185,-6.837 20.4875,-12.1904 30.8401,-18.1106 3.6684,-2.0979 7.5616,-2.2404 11.4126,-0.2584 2.6225,40.5108 5.1788,81.026 7.894,121.5306 1.4689,21.9152 2.9607,43.8339 4.8807,65.7125 3.4899,39.7661 7.2087,79.5132 11.0204,119.25 2.7959,29.1458 5.6046,58.2954 8.9261,87.3841 4.5285,39.6582 9.2893,79.2937 14.4441,118.8747 4.0371,30.999 8.5388,61.9444 13.3526,92.8328 5.6332,36.147 12.2836,72.1382 17.6547,108.3215 5.5652,37.4905 10.5023,75.0855 14.9676,112.7232 2.1913,18.4702 2.6931,37.1408 1.964,57.6062 -45.444,8.2995 -88.9089,14.5063 -132.8155,17.0334 -38.0384,2.1895 -75.8634,0.8753 -113.2083,-7.0488 -14.9033,-3.1621 -29.5121,-7.7117 -44.255,-11.6299 -4.9905,-3.0073 -6.9372,-7.5371 -7.5156,-13.2439 -4.1558,-41.0095 -8.2822,-82.0239 -12.8283,-122.9912 -4.5453,-40.9619 -9.4819,-81.8811 -14.4058,-122.7998 -4.289,-35.6428 -8.6954,-71.2722 -13.2413,-106.8831 -4.2931,-33.6301 -8.6506,-67.2541 -13.3209,-100.8335 -4.5781,-32.917 -9.3381,-65.813 -14.4674,-98.6479 -6.5651,-42.0269 -13.5428,-83.9893 -20.4388,-125.9636 -1.065,-6.4825 -1.7454,-13.3047 -9.9969,-17.7115 z"
        id="BR-arm"
        // onClick={makeClickHandler("BR-arm")}
        style={{ fill: verifySelected("BR-arm") }}
      />
      <path
        fill="#469790"
        opacity="1"
        stroke="none"
        d="m 2823.3339,1220.185 c 2.1206,-1.4859 4.1441,-4.0802 6.3778,-4.2758 13.2577,-1.1613 26.5003,-2.0762 39.1284,4.1329 19.6842,9.6785 39.9162,18.2976 59.264,28.5912 22.7141,12.0845 40.7553,29.766 55.2363,51.0295 33.8664,49.7285 56.1097,103.9174 64.4362,163.8101 5.8689,42.2142 10.3044,84.5124 12.3829,127.0754 2.5967,53.1703 5.3357,106.3336 8.0398,159.4986 2.6369,51.8447 5.3067,103.6878 6.6239,158.0324 -34.0732,16.6057 -62.4923,37.6166 -90.8444,58.6802 -35.3001,26.2256 -70.2128,52.9901 -107.7592,76.023 -22.7612,13.9631 -46.3782,26.2486 -71.4323,35.5958 -4.8265,1.8008 -9.7778,4.6312 -15.9777,1.1021 -0.637,-7.431 -1.313,-15.3189 -1.8155,-25.076 -1.0594,-48.2424 -2.2395,-94.6169 -3.5601,-140.9877 -0.3572,-12.5423 -1.1392,-25.0726 -1.6397,-39.4299 -0.5022,-22.4303 -1.5248,-43.0376 -1.5986,-63.6482 -0.2244,-62.5914 -0.163,-125.1842 -0.028,-187.7761 0.036,-16.6088 0.7385,-33.2162 1.8548,-51.6001 1.2548,-23.0326 1.2788,-44.3173 2.4395,-65.5396 1.9217,-35.1383 4.0275,-70.2845 7.0908,-105.3369 2.5416,-29.083 6.3823,-58.0596 9.9993,-87.0394 3.9574,-31.7073 12.1267,-62.4686 21.7814,-92.8615 z"
        id="BR-shoulder"
        // onClick={makeClickHandler("BR-shoulder")}
        style={{ fill: verifySelected("BR-shoulder") }}
      />
      <path
        fill="#469790"
        opacity="1"
        stroke="none"
        d="m 2747.4125,2284.1838 c 4.3514,2.0552 4.831,5.9475 4.6537,10.0571 -0.5162,11.9546 -0.6095,23.9534 -1.7463,35.8506 -4.4984,47.0823 -3.6773,94.2752 -3.5083,141.4619 0.1794,50.0518 6.4499,99.3514 18.1007,147.9961 0.618,2.5799 1.4734,5.1221 1.856,7.7349 0.9404,6.4224 -3.2659,7.9036 -11.6915,8.4019 -11.9463,0.5564 -20.6266,0.7356 -29.2294,1.6469 -44.3571,4.6997 -88.6644,9.8853 -133.0465,14.3294 -49.017,4.9082 -98.0917,9.2419 -147.15,13.7307 -17.9047,1.6387 -35.8141,3.3398 -53.7567,4.4272 -41.2061,2.4964 -82.4294,4.7203 -123.6592,6.7874 -4.3425,0.2178 -8.7743,-1.3442 -14.3076,-2.272 0,-7.6794 0.062,-14.2934 -0.01,-20.9062 -0.9859,-91.9248 1.6032,-183.8582 -1.5337,-275.7901 -1.4082,-41.2685 -0.2947,-82.6255 -0.1622,-123.9416 0.016,-5.0923 1.0435,-10.1814 1.7382,-16.5103 14.4178,-0.087 27.6617,-0.791 40.8382,-0.1367 54.5272,2.7075 109.1228,4.4402 163.404,10.948 28.4261,3.4077 56.9656,5.9168 85.3441,9.6614 53.4602,7.0547 106.826,14.8213 160.2648,22.0412 14.4713,1.9556 29.064,3.0125 43.601,4.4822 z"
        id="BR-middle-vertebrae"
        // onClick={makeClickHandler("BR-middle-vertebrae")}
        style={{
          fill: verifySelected("BR-middle-vertebrae"),
        }}
      />
      <path
        fill="#46968f"
        opacity="1"
        stroke="none"
        d="m 2763.2478,2640.1994 c 4.4581,0.4517 7.0342,2.5953 8.0362,7.2613 5.1631,24.0434 10.5539,48.0381 15.8752,72.0473 14.5323,65.5691 26.1616,131.6763 36.7313,197.9807 4.0812,25.6009 8.1997,51.1959 10.8535,78.8975 -6.7176,2.6277 -11.9997,3.6885 -17.2551,3.5696 -13.3014,-0.301 -26.5863,-1.2554 -39.8858,-1.7278 -39.2423,-1.3938 -78.4881,-2.6914 -117.7332,-4.0073 -39.2471,-1.3162 -78.4898,-2.8093 -117.7439,-3.8645 -57.2245,-1.5383 -114.4543,-2.9295 -171.6884,-4.0396 -32.6194,-0.6328 -65.2526,-0.531 -97.8782,-0.8925 -5.1488,-0.057 -10.2899,-0.8174 -14.1997,-1.148 -1.5775,-1.7436 -2.2197,-2.1665 -2.4309,-2.7461 -0.4499,-1.2353 -0.8998,-2.5427 -0.9535,-3.8371 -0.1934,-4.6546 -0.3084,-9.3167 -0.3004,-13.9754 0.1565,-91.2275 0.3335,-182.4553 0.5297,-273.6829 0,-0.643 0.2166,-1.3474 0.5321,-1.9108 0.3019,-0.5396 0.8652,-0.9322 2.1785,-2.2708 1.5857,-0.239 4.1226,-0.8513 6.6828,-0.9722 31.9105,-1.5061 63.8582,-2.4275 95.729,-4.5232 40.5064,-2.6638 80.992,-5.8237 121.4114,-9.5778 45.7156,-4.2461 91.3398,-9.4641 137.0183,-14.1184 44.3562,-4.5201 88.7358,-8.8108 133.0881,-13.3685 3.8661,-0.3972 7.605,-2.0329 11.403,-3.0935 z"
        id="BR-lumbar"
        // onClick={makeClickHandler("BR-lumbar")}
        style={{ fill: verifySelected("BR-lumbar") }}
      />
      <path
        fill="#469790"
        opacity="1"
        stroke="none"
        d="m 3175.6327,2815.6894 c 2.0431,25.1585 4.4094,50.2793 1.4933,75.576 -1.0625,9.2175 -0.7207,18.5993 -0.9757,27.9072 -1.2215,44.5681 -5.4673,88.8882 -11.8593,132.9731 -8.9582,61.7864 -18.9524,123.4241 -27.6539,185.2451 -5.4615,38.8011 -9.3749,77.8204 -13.9579,116.7447 -0.6893,5.8542 0.2207,12.1062 -7.2346,16.7029 -13.3371,-1.3443 -23.9048,-2.1868 -34.3848,-3.6551 -40.6681,-5.6977 -80.6907,-4.459 -119.4791,10.6094 -4.4656,1.7349 -8.798,2.9087 -13.4899,1.2515 -2.353,-31.8147 -4.5709,-63.6404 -7.1116,-95.4402 -1.4294,-17.8902 -3.0723,-35.7757 -5.1783,-53.5969 -4.8348,-40.9139 -9.7232,-81.8257 -15.131,-122.6665 -5.4953,-41.503 -11.5749,-82.9292 -17.5194,-124.3719 -5.8505,-40.7859 -11.905,-81.5425 -17.7606,-122.3276 -2.1719,-15.1282 -4.0327,-30.3008 -4.5303,-47.1428 34.2848,9.7798 68.0884,16.3059 102.7103,17.9424 39.863,1.8842 79.5885,-0.5074 119.1314,-5.8892 24.3076,-3.3084 48.6207,-6.5762 72.9314,-9.8621 z"
        id="BR-forearm"
        // onClick={makeClickHandler("BR-forearm")}
        style={{ fill: verifySelected("BR-forearm") }}
      />
      <path
        fill="#479790"
        opacity="1"
        stroke="none"
        d="m 3107.4449,3376.1482 c 3.2423,0.7082 6.4848,1.4162 10.6486,2.3254 0.5155,3.7293 1.4758,7.5225 1.4874,11.3186 0.045,14.6482 -0.4426,29.2991 -0.318,43.9456 0.4724,55.5266 9.6936,109.9084 22.2467,163.7815 8.2986,35.6142 17.0378,71.1328 26.172,106.5415 3.0475,11.8137 4.3057,23.4643 2.9878,35.5063 -3.3271,30.3997 -5.1377,60.9531 -11.1534,91.0606 -10.0891,50.4939 -25.0827,99.2844 -48.5421,145.2575 -8.7973,17.2395 -19.2057,33.482 -31.6158,48.3924 -8.1469,9.7885 -17.2126,18.5444 -28.2305,25.0815 -6.9436,4.1196 -14.2591,7.103 -22.5203,7.3342 -13.7013,0.3838 -25.483,-6.4873 -29.0553,-19.6074 -3.1005,-11.3874 -8.7821,-20.801 -16.2277,-29.3808 -20.3774,-23.4817 -21.675,-49.1893 -8.6811,-76.1912 5.754,-11.957 12.2527,-23.7068 19.7579,-34.6262 6.7973,-9.8887 9.0485,-20.0196 8.8794,-31.8054 -0.5828,-40.6167 -0.5832,-81.2454 -0.3933,-121.8677 0.051,-10.8445 -1.8445,-20.9741 -5.428,-31.176 -8.8153,-25.0953 -17.876,-50.1622 -25.1861,-75.7127 -8.0649,-28.1887 -12.0299,-57.2297 -13.5422,-86.5837 -2.5333,-49.179 -5.3576,-98.3426 -8.0078,-147.5157 -0.1786,-3.3139 0.076,-6.6511 2.0994,-11.9272 41.0798,-17.3032 81.35,-21.6133 122.9403,-15.51 10.4032,1.5266 21.1132,0.9624 31.6821,1.3589 z"
        id="BR-hand"
        // onClick={makeClickHandler("BR-hand")}
        style={{ fill: verifySelected("BR-hand") }}
      />
      <path
        fill="#469790"
        opacity="1"
        stroke="none"
        d="m 2523.6045,6487.9695 c 4.4742,0.726 8.9485,1.4521 13.8193,2.2426 0.661,3.2134 1.4149,5.7232 1.6685,8.2828 4.2649,43.0429 7.7895,86.1738 12.9798,129.104 2.4645,20.3837 7.9944,40.4057 12.2968,60.5571 3.0532,14.3013 6.9296,28.4487 9.4206,42.8408 2.3953,13.8394 1.8757,27.7344 -1.3809,41.6362 -3.6044,15.3868 -10.7939,28.3789 -21.5696,39.837 -13.5152,14.3696 -30.5296,23.5102 -47.4419,32.8032 -3.3073,1.8164 -7.6292,2.4336 -11.4965,2.48 -31.2983,0.374 -62.6274,1.3159 -93.8874,0.2812 -13.7373,-0.4551 -27.6686,-4.0186 -40.9251,-8.0845 -24.6086,-7.5478 -43.0833,-23.2915 -55.2753,-46.1396 -0.94,-1.7617 -1.7823,-3.5772 -2.7577,-5.3184 -9.9265,-17.7241 -16.9579,-36.0591 -12.1396,-56.9248 2.7675,-80.5312 5.5017,-161.0639 8.3859,-241.5913 0.1725,-4.8135 -2.1153,-10.9604 8.073,-12.894 5.0981,0.7299 7.0728,1.1079 9.0556,1.1572 56.526,1.4067 112.9634,4.4351 169.3472,8.6943 13.8892,1.0489 27.8821,0.7276 41.8273,1.0362 z"
        id="BR-heels"
        // onClick={makeClickHandler("BR-heels")}
        style={{ fill: verifySelected("BR-heels") }}
      />
      <path
        fill="#479790"
        opacity="1"
        stroke="none"
        d="m 1623.693,3684.3528 c -5.2089,0.4238 -10.4179,0.8476 -16.7802,1.3654 -0.9373,-4.0959 -2.3137,-7.7592 -2.5489,-11.4946 -3.0122,-47.8225 -6.6799,-95.6616 -4.4839,-143.6096 1.3075,-28.5498 3.5433,-57.0913 6.4479,-85.5256 3.9187,-38.3619 8.4973,-76.6673 13.5033,-114.9036 5.2648,-40.2129 11.3166,-80.3235 17.1206,-120.4651 4.7567,-32.8972 9.5533,-65.7893 14.519,-98.6555 4.6658,-30.8816 9.5762,-61.7258 16.1841,-93.1621 30.0485,43.9638 53.8396,90.7253 72.7932,139.9826 23.1069,60.0511 37.3685,122.1416 42.1137,186.2181 3.8064,51.3994 0.1631,102.5161 -12.1495,152.6823 -10.2218,41.6475 -25.8189,81.1057 -51.0325,116.2994 -23.5193,32.8281 -52.5271,58.1623 -92.3994,69.0661 -1.2063,0.3298 -2.1965,1.45 -3.2874,2.2022 z"
        id="BL-sciatic"
        // onClick={makeClickHandler("BL-sciatic")}
        style={{ fill: verifySelected("BL-sciatic") }}
      />
      <path
        fill="#469790"
        opacity="1"
        stroke="none"
        d="m 1627.6287,2807.4648 c -4.8338,35.583 -9.5055,71.189 -14.5441,106.7429 -5.785,40.8199 -11.9809,81.5816 -17.8406,122.3909 -6.2382,43.4448 -12.6165,86.8733 -18.3121,130.3904 -3.7968,29.009 -6.9214,58.1218 -9.6025,87.2568 -3.8398,41.7261 -7.0828,83.5081 -10.3899,125.2813 -0.7873,9.9441 -0.8189,19.9482 -2.8074,32.0649 -11.96,-0.3381 -22.479,-2.271 -32.6277,-5.4035 -24.8002,-7.6553 -49.8281,-7.7937 -75.1431,-3.678 -11.8275,1.9228 -23.7014,3.5678 -35.5089,5.6023 -6.4762,1.1157 -12.8582,2.7778 -19.2827,4.1936 -3.6403,-1.4929 -4.2716,-4.6631 -4.4939,-8.0615 -1.0388,-15.8851 -1.5612,-31.8243 -3.2297,-47.6429 -2.9903,-28.3493 -6.2148,-56.6902 -10.1692,-84.9184 -5.7953,-41.3704 -12.4501,-82.6194 -18.4923,-123.9561 -4.9871,-34.1194 -10.1617,-68.2263 -14.2486,-102.4582 -3.9306,-32.9224 -6.5584,-66 -9.7888,-99.0064 -0.8303,-8.4841 0.4744,-17.3784 -6.2111,-25.5381 -1.524,-31.2321 -1.524,-61.9826 -0.5949,-95.6416 3.5394,-5.8515 6.1496,-8.7942 8.1148,-11.0097 43.4106,1.8515 85.8991,4.0188 128.4134,5.3808 45.285,1.4505 90.451,-0.5591 135.3384,-7.1704 10.4828,-1.5437 20.9477,-3.2095 31.4209,-4.8191 z"
        id="BL-forearm"
        // onClick={makeClickHandler("BL-forearm")}
        style={{ fill: verifySelected("BL-forearm") }}
      />
      <path
        fill="#46968f"
        opacity="1"
        stroke="none"
        d="m 1822.6662,5021.9324 c 0.022,-35.3033 0.915,-70.6333 -0.1547,-105.9034 -1.3515,-44.5595 -4.918,-88.9912 -9.9162,-133.3486 -12.9144,-114.6104 -37.6488,-226.9668 -62.7943,-339.293 -30.0783,-134.3618 -60.3976,-268.6767 -89.211,-403.3112 -13.6354,-63.7134 -24.8929,-127.9778 -35.576,-192.2671 -8.1761,-49.2022 -14.808,-98.711 -15.9456,-150.5193 209.4211,-1.9654 416.8709,-1.9949 624.3207,-2.0093 2.6566,0 5.3132,0.1968 7.9698,0.3015 -0.9207,33.2949 -1.5412,66.6011 -2.8259,99.8821 -2.5173,65.207 -5.3392,130.4031 -8.1594,195.5979 -2.3597,54.5498 -4.7935,109.0969 -7.3864,163.636 -2.2134,46.5571 -6.0346,93.0805 -6.7537,139.6557 -1.09,70.5889 -1.3423,141.2417 0.4451,211.8067 1.1275,44.5102 4.5784,89.0298 9.5388,133.417 4.6543,41.6479 6.8433,83.5786 9.7757,125.4082 1.3032,18.5893 2.0258,37.2304 2.5536,55.8608 0.8695,30.6983 -2.315,61.126 -5.8832,91.5913 -3.2494,27.7442 -4.7904,55.6885 -9.0384,84.5093 -18.5349,0.8921 -35.1416,0.188 -51.6915,0.8687 -44.392,1.8271 -88.7694,4.0708 -133.1333,6.5009 -25.8306,1.4156 -51.6392,3.3174 -77.429,5.3633 -17.844,1.4151 -35.625,3.6074 -53.4591,5.1685 -28.4045,2.4863 -56.8296,4.7348 -85.246,7.084 z"
        id="BL-leg"
        // onClick={makeClickHandler("BL-leg")}
        style={{ fill: verifySelected("BL-leg") }}
      />
      <path
        fill="#46968f"
        opacity="1"
        stroke="none"
        d="m 1967.5065,6484.1086 c 6.351,-56.4243 5.9608,-113.0454 2.9075,-169.5566 -2.4764,-45.8316 -9.5128,-91.2612 -17.2861,-136.5337 -20.3775,-118.6802 -47.2723,-236.0117 -72.2757,-353.7451 -17.1283,-80.6509 -33.4501,-161.5044 -48.1743,-242.6226 -8.7808,-48.3745 -14.9393,-97.312 -20.1847,-146.2207 -6.8974,-64.311 -7.0944,-128.9809 -3.9732,-193.5351 2.0877,-43.1763 6.5926,-86.231 9.6115,-129.3682 1.8116,-25.8872 2.8453,-51.8291 6.2637,-79.5977 26.5532,-4.2749 51.0535,-6.8974 75.5934,-9.0747 37.1485,-3.2959 74.3036,-6.5649 111.495,-9.3149 33.2021,-2.4546 66.4357,-4.7075 99.6951,-6.1045 37.2539,-1.5654 74.5524,-2.0689 111.8314,-3.0342 0.3111,5.96 0.8795,11.919 0.893,17.8794 0.1268,55.9585 -0.5704,111.9307 0.4773,167.8706 0.685,36.5816 3.9319,73.1128 5.9014,109.6734 3.0084,55.8476 7.8065,111.6626 4.7352,167.664 -1.7862,32.5679 -4.273,65.0977 -6.4693,97.6426 -1.3896,20.5913 -2.9452,41.1719 -4.2104,61.7705 -1.8368,29.9067 -3.4044,59.8301 -5.2237,89.7383 -2.0211,33.2236 -4.2044,66.4385 -6.2939,99.6582 -0.2927,4.6523 -0.4977,9.312 -0.6682,13.9707 -1.9,51.9116 -3.1937,103.854 -5.8048,155.73 -4.5923,91.2353 -3.5632,182.4936 -1.9356,273.7446 0.7242,40.6001 3.4495,81.1675 5.4035,121.7432 1.3751,28.5556 1.3982,57.1074 1.5893,85.8906 -11.1959,4.5908 -21.9825,2.3154 -36.0186,3.4111 -35.9911,1.9331 -68.3795,3.3203 -100.655,6.0454 -30.9484,2.6123 -61.7747,6.6582 -92.667,9.9605 -5.2016,0.5556 -10.6935,1.4263 -14.5568,-3.6851 z"
        id="BL-calf"
        // onClick={makeClickHandler("BL-calf")}
        style={{ fill: verifySelected("BL-calf") }}
      />
      <path
        fill="#469790"
        opacity="1"
        stroke="none"
        d="m 1620.8842,2799.8691 c -11.8288,2.146 -22.2508,3.9075 -32.7384,5.085 -15.1302,1.6985 -30.2815,3.4231 -45.472,4.3198 -21.1518,1.2488 -42.3836,2.9402 -63.5181,2.3135 -45.6729,-1.354 -91.3065,-4.0452 -136.9539,-6.2373 -6.3448,-0.3047 -12.1324,-1.5103 -11.9868,-11.6594 1.4384,-24.8814 3.2029,-48.0774 5.246,-71.2491 0.6989,-7.9265 1.5736,-15.9106 3.3394,-23.6467 1.4222,-6.231 4.3073,-12.1277 7.3889,-20.459 -2.6975,-7.103 -1.5349,-16.7585 0.2508,-26.7776 8.2773,-46.4384 16.5134,-92.8969 23.7275,-139.5085 5.3854,-34.7959 9.3321,-69.8194 13.6587,-104.7737 4.9792,-40.2278 10.0061,-80.4546 14.3848,-120.75 3.662,-33.699 6.4494,-67.4934 9.5708,-101.2505 3.4883,-37.7244 7.0128,-75.4458 10.3318,-113.1852 1.7472,-19.867 3.3066,-39.7559 4.5652,-59.6594 2.0141,-31.8514 3.7074,-63.7233 6.7451,-97.66 4.9825,0.37 9.2168,2.3193 12.4551,5.3374 21.3459,19.8934 42.4621,40.0333 63.6824,60.0617 27.9916,26.4194 56.5594,52.1882 87.7067,74.8893 36.3615,26.5014 76.684,41.5407 122.1632,41.089 1.8071,0.7581 3.6141,1.5164 6.6172,2.7764 -2.7516,16.7759 -5.5512,33.6889 -8.2964,50.6106 -7.5627,46.6174 -15.2878,93.2095 -22.5957,139.8669 -6.3819,40.7447 -12.6528,81.5135 -18.2875,122.366 -4.9991,36.2446 -9.1291,72.6103 -13.5067,108.939 -3.5817,29.7241 -7.1512,59.4516 -10.381,89.2153 -3.374,31.0915 -6.2701,62.2344 -9.4971,93.3423 -2.8834,27.7971 -5.9607,55.5744 -8.8863,83.3674 -0.6019,5.7175 -2.8322,10.1973 -9.7137,13.2368 z"
        id="BL-arm"
        // onClick={makeClickHandler("BL-arm")}
        style={{ fill: verifySelected("BL-arm") }}
      />
      <path
        fill="#469790"
        opacity="1"
        stroke="none"
        d="m 1735.7096,2637.3965 c 5.197,-25.9532 10.9995,-51.802 15.475,-77.8792 12.3055,-71.6997 10.754,-143.9629 7.4756,-216.2287 -0.8407,-18.5306 -2.7298,-37.013 -2.2346,-57.5203 39.5765,-6.2927 77.2549,-10.5449 114.9234,-14.8833 41.6357,-4.7954 83.2167,-10.113 124.9042,-14.3994 53.6053,-5.512 107.2603,-10.6128 160.9598,-15.1018 23.8501,-1.9934 47.8583,-2.169 71.8031,-2.8916 4.3795,-0.1324 8.7994,1.0752 14.0362,1.7761 0.4335,7.0508 1.0762,12.9053 1.1099,18.7632 0.3255,56.5825 0.6297,113.1653 0.7759,169.7485 0.1221,47.27 0.026,94.5405 0.025,141.8108 -7e-4,29.9602 0.084,59.9207 -0.093,89.8799 -0.03,5.0588 -1.1977,10.1111 -1.8597,15.323 -3.8585,0.6833 -6.415,1.5571 -8.9596,1.5242 -39.9299,-0.5186 -79.8847,-0.4612 -119.7789,-1.9793 -32.5596,-1.2395 -65.0625,-4.1875 -97.561,-6.7519 -35.815,-2.826 -71.6275,-5.7559 -107.3844,-9.2249 -49.6615,-4.8181 -99.2714,-10.1689 -148.9003,-15.3213 -8.583,-0.8909 -17.3351,-1.2422 -24.7163,-6.644 z"
        id="BL-middle-vertebrae"
        // onClick={makeClickHandler("BL-middle-vertebrae")}
        style={{
          fill: verifySelected("BL-middle-vertebrae"),
        }}
      />
      <path
        fill="#469790"
        opacity="1"
        stroke="none"
        d="m 1722.2715,2084.884 c -0.4631,0.9487 -0.9262,1.8975 -3.7394,3.8045 -4.9938,0.6247 -7.6286,0.1713 -10.2823,-0.024 -43.0056,-3.1704 -81.9711,-17.0143 -115.7696,-44.1853 -16.0892,-12.9343 -32.4021,-25.6705 -47.6487,-39.556 -33.982,-30.9484 -67.3287,-62.5939 -101.0234,-93.8593 -3.3736,-3.1305 -7.3579,-5.6028 -11.0588,-8.3805 -0.6719,-4.5438 -2.0651,-9.1155 -1.8919,-13.6268 0.7899,-20.5786 2.0178,-41.1405 3.0933,-61.708 0.2083,-3.9843 0.4993,-7.965 0.6492,-11.9513 1.7486,-46.4912 2.7507,-93.0252 5.4188,-139.4633 2.59,-45.081 6.866,-90.0657 10.4361,-135.0898 3.1019,-39.1194 8.7753,-77.8721 17.0177,-116.2335 5.0282,-23.402 11.5177,-46.3877 20.3638,-68.6794 19.9389,-50.2446 53.8614,-88.1937 101.3165,-113.6875 16.9285,-9.0941 34.9532,-16.1535 52.5121,-24.065 5.5546,-2.503 11.2798,-4.054 18.8029,-0.4803 1.496,0.9769 1.7615,1.2312 1.7205,2.9098 0.5474,4.9445 1.1358,8.2106 1.5621,13.1259 0.2436,4.2773 0.4838,6.9435 1.0795,9.5277 11.6884,50.7042 19.4317,102.004 21.9901,153.9749 2.1928,44.5437 3.2946,89.14 5.2199,133.6982 2.1546,49.8675 4.748,99.7158 7.0477,149.5773 1.9935,43.2253 3.7671,86.4608 5.7859,129.6849 1.18,25.2648 2.5422,50.5225 4.0164,75.7719 1.9783,33.8809 4.2419,67.7453 6.1999,101.6273 1.6897,29.2389 3.0613,58.4962 4.7579,87.7347 0.1869,3.2204 1.5866,6.3705 2.4238,9.5531 z"
        id="BL-should"
        // onClick={makeClickHandler("BL-should")}
        style={{ fill: verifySelected("BL-should") }}
      />
      <path
        fill="#469790"
        opacity="1"
        stroke="none"
        d="m 1668.0357,2999.1753 c 5.1097,-31.3865 10.2642,-62.7659 15.3206,-94.1609 13.3846,-83.1038 29.5374,-165.6658 48.5817,-247.6563 0.6893,-2.9675 3.1488,-5.5241 7.5857,-7.9404 14.0397,1.5774 25.2736,2.8538 36.5148,4.0625 32.437,3.4888 64.8581,7.1401 97.3211,10.3691 33.7966,3.3621 67.6043,6.6976 101.4622,9.3406 49.7864,3.8867 99.5882,7.7622 149.4472,10.4685 35.2286,1.9121 70.564,1.8338 105.8484,2.7671 5.1182,0.1353 10.8044,-1.4106 14.7858,3.5362 2.7123,19.059 3.8474,197.0378 1.744,281.5793 -0.096,3.8611 -0.7968,7.7073 -1.3012,12.3437 -5.2789,0.5899 -9.7541,1.4634 -14.2413,1.5296 -25.9787,0.384 -51.9622,0.4563 -77.9392,0.914 -7.2775,0.128 -14.5381,1.2024 -23.6709,1.6846 -46.3313,0.5017 -90.8099,0.7312 -135.2622,1.9275 -59.0252,1.5884 -118.0309,3.905 -177.0432,5.96 -35.1405,1.2236 -70.272,2.7485 -105.4189,3.7256 -14.5628,0.4047 -29.1555,-0.2681 -43.7346,-0.4507 z"
        id="BL-lumbar"
        // onClick={makeClickHandler("BL-lumbar")}
        style={{ fill: verifySelected("BL-lumbar") }}
      />
      <path
        fill="#479790"
        opacity="1"
        stroke="none"
        d="m 1555.0373,3424.8098 c -0.8395,47.9534 -3.2555,95.8381 -8.2278,143.532 -3.8095,36.5403 -12.9282,71.9831 -24.9046,106.7046 -6.075,17.6123 -14.1271,34.9677 -17.0603,53.1218 -3.0449,18.8467 -1.082,38.5178 -1.1395,57.8286 -0.085,28.648 0.3537,57.3037 -0.2071,85.9409 -0.2213,11.2962 2.6432,20.553 9.8462,29.2796 10.2428,12.4084 17.4479,26.6486 22.6475,41.9546 3.5022,10.3085 4.375,20.7534 3.8547,31.4953 -0.4702,9.7075 -3.5253,18.5354 -10.254,25.3836 -9.9998,10.1767 -16.9164,22.1577 -22.9121,34.8696 -1.4132,2.9961 -3.1174,5.9072 -5.0486,8.5986 -9.7309,13.5618 -23.4357,17.9207 -38.5118,10.5027 -8.793,-4.3262 -16.9999,-10.5161 -24.2272,-17.21 -14.2769,-13.2229 -24.8404,-29.5278 -34.5723,-46.2585 -28.0705,-48.2585 -45.614,-100.4165 -56.8607,-154.8704 -5.5273,-26.7622 -9.5392,-53.7917 -10.9513,-81.0439 -1.6694,-32.217 5.0082,-63.5784 12.7346,-94.6323 10.4421,-41.9705 20.7766,-83.9322 27.6032,-126.7088 5.1475,-32.2556 8.5183,-64.6382 10.0174,-97.2419 0.2134,-4.6414 0.4711,-9.2803 0.7767,-15.2593 4.0298,-1.3979 8.1893,-2.8411 15.6939,-4.3862 19.1,-2.3008 34.855,-4.4986 50.6097,-6.6985 15.1839,-2.1196 30.5584,-3.6548 45.5544,-0.1736 18.6877,4.3379 37.0412,10.1155 55.539,15.2715 z"
        id="BL-hand"
        // onClick={makeClickHandler("BL-hand")}
        style={{ fill: verifySelected("BL-hand") }}
      />
      <path
        fill="#469790"
        opacity="1"
        stroke="none"
        d="m 2211.5597,6479.2571 c 0.2053,34.5991 -0.1898,69.2158 0.7848,103.7929 0.9743,34.5586 2.871,69.1084 5.2092,103.605 1.765,26.04 0.662,51.9087 -2.6612,77.6597 -3.71,28.7485 -17.5784,51.2641 -43.5021,65.518 -1.747,0.9605 -3.4862,1.9346 -5.2308,2.8995 -27.3402,15.1201 -27.3384,15.2734 -58.3359,15.6464 -24.6156,0.2964 -49.2368,0.1148 -73.8551,0.2237 -25.8038,0.1137 -48.0403,-9.9761 -68.1094,-24.9483 -22.2003,-16.5625 -32.4785,-39.771 -34.1428,-67.0781 -1.104,-18.1133 1.4477,-35.8789 5.8357,-53.3428 8.5908,-34.1914 15.835,-68.6328 20.3753,-103.6059 1.7972,-13.8438 3.4386,-27.7158 4.7335,-41.6143 1.7888,-19.1997 3.1827,-38.4365 6.7651,-59.4604 8.6406,-2.3389 15.2872,-2.6621 21.8791,-3.4453 65.9519,-7.8287 132.0239,-14.2198 198.4311,-16.523 7.258,-0.2519 14.5483,0.4258 21.8235,0.6729 z"
        id="BL-heels"
        // onClick={makeClickHandler("BL-heels")}
        style={{ fill: verifySelected("BL-heels") }}
      />
      <path
        fill="#45968f"
        opacity="1"
        stroke="none"
        d="m 1752.3397,2274.8647 c -3.9721,-35.7043 -7.8711,-71.4172 -11.9425,-107.1103 -2.6377,-23.1248 -5.4298,-46.2329 -8.4005,-69.3169 -0.493,-3.8311 -2.2573,-7.4981 -3.8113,-14.5657 -2.0261,-35.8632 -3.5503,-68.4088 -5.3505,-100.9391 -3.5644,-64.4098 -7.4656,-128.8011 -10.8785,-193.2187 -2.8847,-54.4482 -5.4769,-108.9137 -7.8371,-163.3874 -3.1089,-71.7578 -6.1189,-143.5221 -8.6723,-215.3012 -1.4453,-40.628 -5.2235,-80.9734 -12.7629,-120.8938 -3.6867,-19.5208 -8.8152,-38.7692 -13.5722,-59.6004 -0.859,-4.3654 -1.4371,-7.2716 -1.7619,-11.1584 0.2361,-1.6563 0.2188,-2.3314 0.2016,-3.0065 0,0 -0.1407,-0.1185 1.7875,-0.1753 5.2564,-0.1081 8.5846,-0.2042 11.9128,-0.2044 179.7628,-0.01 359.5256,-0.01 539.2883,-0.01 3.3289,0 6.6865,-0.2556 9.9791,0.094 3.7264,0.3955 7.3945,1.3402 12.1216,2.2438 0,7.6225 -0.01,14.2009 6e-4,20.7791 0.1389,177.7625 0.2786,355.5251 0.4189,533.2875 0.01,5.9921 -0.084,11.9866 0.064,17.9752 0.082,3.3102 0.5675,6.6105 0.7252,11.7824 -0.1747,45.1273 -0.2901,88.3876 -0.211,131.6477 0.107,58.5625 0.4028,117.1247 0.5407,175.6872 0.081,34.6082 0.053,69.2168 -0.027,103.8252 -0.012,5.1817 1.015,10.7466 -4.1279,15.418 -4.0192,0.2446 -8.6378,0.5469 -13.259,0.8035 -28.5536,1.5854 -57.1482,2.6416 -85.6511,4.872 -35.1354,2.7493 -70.218,6.2435 -105.2805,9.8353 -43.669,4.4733 -87.3069,9.2602 -130.9343,14.1242 -40.9753,4.5686 -81.9122,9.48 -122.8829,14.0911 -9.8582,1.1096 -19.7827,1.6301 -29.6768,2.4211 z"
        id="BL-shoulder-blade"
        // onClick={makeClickHandler("BL-shoulder-blade")}
        style={{
          fill: verifySelected("BL-shoulder-blade"),
        }}
      />
    </svg >
  );
}

// i18n setup
import i18n from "i18next";
import moment from "moment";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import JSON data
import en from "./en";
import es from "./es";
import pt from "./pt";
import fr from "./fr";
import it from "./it";
import de from "./de";
import ar from "./ar";
import cn from "./cn";

const resources = {
  en,
  es,
  pt,
  fr,
  it,
  de,
  ar,
  cn,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: ["en", "es", "pt", "fr", "it", "de", "ar", "cn"],
    lng: "pt",
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

i18n.on("languageChanged", (lng) => {
  moment.locale(i18n.language);
  document.documentElement.setAttribute("lang", lng);
  if (lng) moment.locale(lng.toLowerCase());
});

document.documentElement.lang = i18n.language;

export default i18n;

import { useState } from "react";

export default function AdminHeader() {
    const [isScrollingDown, setIsScrollingDown] = useState(false);

    return (

        <div
            className={`fixed flex py-2 pl-2  top-0 w-full bg-[#F8F7F6] px-2 transition-transform duration-300 justify-start z-50 ${isScrollingDown ? "-translate-y-full" : "translate-y-0"
                }`}
        >
            <header className="flex">
                <h1 className="text-2xl font-semibold text-gray-800">Confirmed attendees</h1>
            </header>
        </div>
    );
}

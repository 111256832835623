import React from "react";
import { IIconProps } from "../types";

export const IconAlert: React.FC<IIconProps> = (props) => {
  return (
    <svg
      className="mx-auto mb-4 text-gray-400 w-16 h-16"
      aria-hidden="true"
      fill="#f97316"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
  );
};

export const IconClose: React.FC<IIconProps> = (props) => {
  return (
    <svg
      height="1em"
      width="1em"
      aria-hidden="true"
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
      />
    </svg>
  );
};

export const IconSearch: React.FC<IIconProps> = (props) => {
  return (
    <svg
      viewBox="0 0 1024 1024"
      fill="#94a3b8"
      height="1.5em"
      width="1.5em"
      {...props}
    >
      <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z" />
    </svg>
  );
};

export const IconArrowDown: React.FC<IIconProps> = (props) => {
  return (
    <svg
      className="hidden fill-current sm:block"
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.410765 0.910734C0.736202 0.585297 1.26384 0.585297 1.58928 0.910734L6.00002 5.32148L10.4108 0.910734C10.7362 0.585297 11.2638 0.585297 11.5893 0.910734C11.9147 1.23617 11.9147 1.76381 11.5893 2.08924L6.58928 7.08924C6.26384 7.41468 5.7362 7.41468 5.41077 7.08924L0.410765 2.08924C0.0853277 1.76381 0.0853277 1.23617 0.410765 0.910734Z"
        fill=""
      />
    </svg>
  );
};

export const IconPencilOutline: React.FC<IIconProps> = (props) => {
  return (
    <svg
      viewBox="0 0 512 512"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={32}
        d="M364.13 125.25L87 403l-23 45 44.99-23 277.76-277.13-22.62-22.62zM420.69 68.69l-22.62 22.62 22.62 22.63 22.62-22.63a16 16 0 000-22.62h0a16 16 0 00-22.62 0z"
      />
    </svg>
  );
};

export const IconSignOut: React.FC<IIconProps> = (props) => {
  return (
    <svg
      viewBox="0 0 15 15"
      fill="currentColor"
      height="1.5em"
      width="1.5em"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3 1a1 1 0 00-1 1v11a1 1 0 001 1h7.5a.5.5 0 000-1H3V2h7.5a.5.5 0 000-1H3zm9.604 3.896a.5.5 0 00-.708.708L13.293 7H6.5a.5.5 0 000 1h6.793l-1.397 1.396a.5.5 0 00.708.708l2.25-2.25a.5.5 0 000-.708l-2.25-2.25z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const IconCreateTicket: React.FC<IIconProps> = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#FFF"
        stroke="none"
      >
        <path
          d="M2345 5109 c-669 -69 -1264 -445 -1636 -1035 -88 -139 -182 -340
   -234 -498 -65 -197 -115 -462 -115 -608 l0 -58 174 0 174 0 7 83 c61 756 542
   1407 1227 1661 333 124 712 143 1063 55 565 -142 1055 -586 1271 -1152 68
   -179 114 -380 129 -564 l7 -83 174 0 174 0 0 58 c0 32 -5 96 -10 142 -65 525
   -297 1005 -663 1371 -363 362 -816 578 -1326 629 -117 11 -298 11 -416 -1z"
        />
        <path
          d="M2480 3703 l-36 -4 -42 -128 c-23 -71 -50 -135 -60 -143 -9 -9 -43
   -25 -75 -37 -32 -11 -76 -30 -97 -41 -60 -31 -90 -25 -220 40 l-119 60 -80
   -81 -81 -80 60 -119 c65 -130 71 -160 40 -220 -11 -21 -30 -65 -41 -97 -12
   -32 -28 -66 -37 -75 -8 -10 -72 -36 -142 -60 l-128 -42 -6 -58 c-4 -32 -4 -84
   0 -116 l6 -58 128 -42 c70 -24 134 -50 142 -60 9 -9 25 -43 37 -75 11 -32 30
   -76 41 -97 31 -60 25 -90 -40 -220 l-60 -119 81 -80 80 -81 119 60 c130 65
   160 71 220 40 21 -11 65 -30 97 -41 32 -12 66 -28 75 -37 10 -8 36 -72 60
   -142 l42 -128 58 -6 c32 -4 84 -4 116 0 l58 6 42 128 c24 70 50 134 60 142 9
   9 43 25 75 37 32 11 76 30 97 41 60 31 90 25 220 -40 l119 -60 80 81 81 80
   -60 119 c-65 130 -71 160 -40 220 11 21 30 65 41 97 12 32 28 66 37 75 8 10
   72 36 142 60 l128 42 6 58 c4 32 4 84 0 116 l-6 58 -128 42 c-70 24 -134 50
   -142 60 -9 9 -25 43 -37 75 -11 32 -30 76 -41 97 -31 60 -25 90 40 220 l60
   119 -81 80 -80 81 -119 -60 c-130 -65 -160 -71 -220 -40 -21 11 -65 30 -97 41
   -32 12 -66 28 -75 37 -10 8 -36 72 -60 142 l-42 128 -50 6 c-52 6 -82 6 -146
   -1z m247 -549 c204 -58 366 -220 429 -429 28 -95 24 -259 -9 -353 -35 -101
   -81 -175 -153 -246 -71 -72 -145 -118 -246 -153 -94 -33 -258 -37 -353 -9
   -209 63 -368 222 -431 431 -24 82 -24 248 0 330 72 240 259 403 516 449 46 8
   188 -3 247 -20z"
        />
        <path
          d="M2453 2985 c-174 -47 -295 -181 -326 -360 -48 -283 215 -546 498
   -498 242 41 408 266 368 498 -44 259 -295 426 -540 360z"
        />
        <path
          d="M303 2730 c-81 -17 -151 -71 -189 -146 -18 -37 -19 -69 -22 -713 -3
   -748 -4 -730 62 -807 18 -22 55 -50 82 -64 l49 -25 433 -3 432 -3 -2 883 -3
   883 -400 1 c-220 1 -419 -2 -442 -6z"
        />
        <path
          d="M3977 2734 c-4 -4 -7 -403 -7 -886 l0 -879 433 3 432 3 49 25 c27 14
   64 42 82 64 66 77 65 59 62 807 -3 644 -4 676 -22 713 -27 53 -80 104 -135
   130 -44 20 -62 21 -466 24 -231 2 -424 0 -428 -4z"
        />
        <path
          d="M440 765 c0 -14 7 -61 16 -103 45 -222 181 -399 378 -496 147 -72
   180 -76 676 -76 l430 0 0 175 0 175 -407 0 c-441 0 -482 4 -566 53 -86 51
   -156 156 -165 248 l-5 49 -178 0 -179 0 0 -25z"
        />
        <path
          d="M2149 501 l-29 -29 0 -207 0 -207 29 -29 29 -29 332 0 c338 0 359 2
   387 39 9 11 12 77 13 228 l0 211 -31 26 -31 26 -335 0 -335 0 -29 -29z"
        />
      </g>
    </svg>
  );
};

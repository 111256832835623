import i18n from "i18next";

export default function formatTime(minutes: number) {
  if (typeof minutes !== "number" || minutes < 0) {
    return;
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours === 0) {
    return `${remainingMinutes} ${i18n.t("default.minutes")}`;
  } else if (hours === 1) {
    return remainingMinutes === 0
      ? `${hours} ${i18n.t("default.hour")}`
      : `${hours}h${remainingMinutes.toString().padStart(2, "0")} ${i18n.t(
          "default.minutes"
        )}`;
  } else {
    return remainingMinutes === 0
      ? `${hours} ${i18n.t("default.hours")}`
      : `${hours}h${remainingMinutes.toString().padStart(2, "0")} ${i18n.t(
          "default.hours"
        )}`;
  }
}

import { useMassage } from "../provider/massage";
import { IconClose } from "./Icons";

export default function CloseButton() {
  const { setShowPopupClose } = useMassage();

  const handleClosePopup = () => {
    setShowPopupClose(true);
  };

  return (
    <button
      onClick={handleClosePopup}
      className="mt-2 mx-4 hover:bg-[#529A98] w-8 h-8 rounded flex justify-center items-center"
    >
      <IconClose color="#fff" />
    </button>
  );
}

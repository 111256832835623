import { useNavigate, useSearchParams } from "react-router-dom";
import { useMassage } from "../provider/massage";
import Brand from "../components/Brand";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

function Start() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setStep, setMassageRequest } = useMassage();
  const [searchParams] = useSearchParams();
  const getPrefilledName = searchParams.get("name") ?? "";
  const getPrefilledMassage = {
    name: searchParams.get("massageOption") ?? "",
  };
  const getPrefilledDuration = Number(searchParams.get("massageDuration"));
  const getPrefilledPhone = searchParams.get("phone") ?? "";
  const getPrefilledReferralSource = searchParams.get("massageSource");

  const submit = useCallback(
    (hasBooking: boolean) => {
      try {
        setMassageRequest((prev) => ({
          ...prev!,
          hasBooking,
          ...(getPrefilledPhone && { phone: getPrefilledPhone }),
          ...(getPrefilledName && { name: getPrefilledName }),
          ...(getPrefilledMassage.name && { massage: getPrefilledMassage.name }),
          ...(getPrefilledDuration && { duration: getPrefilledDuration }),
          ...(getPrefilledReferralSource && { referralSource: getPrefilledReferralSource })
        }));
        setStep(1);
        navigate("/home");
      } catch { }
    },
    [navigate, setMassageRequest, setStep]
  );

  return (
    <>
      <div className="min-h-screen bg-gradient-to-b from-[#255D67] to-white flex items-center justify-center py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 p-10 bg-white rounded-xl shadow-md">
          <div>
            <h1 className="font-bold text-gray-700 text-center text-2xl mb-0">
              {t("default.greetings")}
            </h1>
            <h1 className="font-bold text-gray-700 text-center text-2xl mb-6">
              {t("default.welcome_to_eco_massage")}
            </h1>
            <p className="text-gray-600 text-center mb-4">
              {t("default.we_are_delighted_to_help")}
            </p>
            <p className="text-gray-600 text-center mb-6">
              {t("default.are_you_ready_to_get_started")}
            </p>
          </div>
          <div className="flex justify-center space-x-6">
            <button
              type="button"
              onClick={() => submit(false)}
              className="w-52 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#235B66] hover:bg-[#1E4F5B] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              {t("default.yes_im_ready")}
            </button>
          </div>
        </div>
      </div>
      <Brand />
    </>
  );
}

export default Start;

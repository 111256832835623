import { useCallback } from "react";
import { useMassage } from "../provider/massage";
import { useTranslation } from "react-i18next";

function StepTwo() {
  const { t } = useTranslation();
  const { setStep, setMassageRequest } = useMassage();

  const onSubmit = useCallback(
    (hasPain: any) => {
      try {
        setMassageRequest((prev) => ({
          ...prev!,
          hasPain,
        }));
        setStep(3);
      } catch {}
    },
    [setMassageRequest, setStep]
  );
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#255D67] to-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 p-10 bg-white rounded-xl shadow-md">
        <div>
          <h1 className="font-bold text-gray-700 text-center text-2xl mb-6">
            {t("default.pain_or_tension")}
          </h1>
        </div>
        <div className="flex justify-center space-x-6">
          <button
            type="button"
            onClick={() => onSubmit(false)}
            className="w-32 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#235B66] hover:bg-[#1E4F5B] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            {t("default.no")}
          </button>
          <button
            type="button"
            onClick={() => onSubmit(true)}
            className="w-32 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#235B66] hover:bg-[#1E4F5B] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            {t("default.yes")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default StepTwo;

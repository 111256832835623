import { useEffect, useMemo } from "react";
import StepOne from "../components/StepOne";
import StepTwo from "../components/StepTwo";
import { useMassage } from "../provider/massage";
import StepThree from "../components/StepThree";
import Brand from "../components/Brand";

function Home() {
  const { step } = useMassage();

  const renderSteps = useMemo(() => {
    switch (step) {
      case 1:
        return <StepOne />;
      case 2:
        return <StepTwo />;
      case 3:
        return <StepThree />;
      default:
        return <StepTwo />;
    }
  }, [step]);

  return (
    <div className="items-center justify-center min-h-screen">
      {renderSteps}

      <Brand />
    </div>
  );
}

export default Home;

function Brand() {
  return (
    <div className="w-full flex justify-center items-center p-2 -mt-8 bottom-0">
      <p className="font-bold text-xs">
        Powered by{" "}
        <a
          href="https://360solved.com"
          target="blank"
          className="text-blue-500 underline"
        >
          360Solved.com
        </a>
      </p>
    </div>
  );
}

export default Brand;

import { SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IconSearch } from "./Icons";
import { countries } from "../utils/countries";
import { useMassage } from "../provider/massage";
import { SelectCountryProps } from "../types";
import { TCountrySelect } from "../utils/countriesSelect";

export function SelectCountry(props: SelectCountryProps) {
    const { register, setValue, watch } = useFormContext();
    const { t } = useTranslation();
    const { customerCountry, setCustomerCountry } = useMassage();
    const [searchCountry, setSearchCountry] = useState<string>("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const selectedCountry = watch(props.name);

    useEffect(() => {
        const country = countries.find(country => country.name === selectedCountry);
        if (country) setCustomerCountry(country);
    }, [selectedCountry, setCustomerCountry]);

    const filteredCountries = useMemo(() => {
        return countries.filter((country) =>
            country.name.toLowerCase().includes(searchCountry.toLowerCase())
        );
    }, [searchCountry,]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const renderDropdown = useMemo(() => {

        const handleCountrySelect = (country: SetStateAction<TCountrySelect>) => {
            setCustomerCountry(country);
            setValue(props.name, country.name);
            setIsDropdownOpen(false);
        };

        return (
            <div
                ref={dropdownRef}
                className={`z-10 top-[2.5rem] bg-white absolute divide-y divide-slate-100 rounded-lg shadow max-h-[300px] ${isDropdownOpen ? 'block' : 'hidden'}`}
            >
                <ul className="py-2 text-sm text-slate-700 overflow-y-auto max-h-[300px]" aria-labelledby="dropdown-country-button">
                    <li className="inline-flex w-full px-4 py-2 text-sm text-slate-700">
                        <div className="inline-flex items-center border border-slate-200 p-2 w-full rounded">
                            <IconSearch />
                            <input
                                type="search"
                                className="outline-none w-full ml-2"
                                value={searchCountry}
                                onChange={(e) => setSearchCountry(e.target.value)}
                            />
                        </div>
                    </li>
                    {filteredCountries.length ? (
                        filteredCountries.map((country, i) => (
                            <li key={i} className="inline-flex w-full px-4 py-2 text-sm text-slate-700 hover:bg-slate-200">
                                <button
                                    type="button"
                                    onClick={() => handleCountrySelect(country)}
                                    className="inline-flex w-full px-4 py-2 text-sm text-slate-700 hover:bg-slate-200"
                                    role="menuitem"
                                >
                                    <div className="inline-flex items-center">
                                        <img
                                            src={`${process.env.REACT_APP_API_FLAGS}/64x48/${country.flag}.png`}
                                            alt="flag"
                                            className="h-3 w-4 mr-2"
                                        />
                                        {country.name}
                                    </div>
                                </button>
                            </li>
                        ))
                    ) : (
                        <li className="inline-flex w-full px-4 py-2 text-sm text-slate-700 dark:text-slate-200">
                            <div className="inline-flex items-center justify-center p-2 w-full">
                                <span>{t("default.no_results")}</span>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        );
    }, [searchCountry, filteredCountries, t, isDropdownOpen, props.name, setCustomerCountry, setValue]);

    return (
        <div className="flex items-center relative bg-white dark:bg-form-input">
            <button
                id="dropdown-country-button"
                onClick={() => setIsDropdownOpen(prev => !prev)}
                className="focus:outline-none relative w-full appearance-none rounded border border-stroke bg-slate-100 py-[0.6rem] px-5 transition focus:border-primary active:border-primary flex items-center justify-center"
                type="button"
            >
                <img
                    src={
                        customerCountry?.flag
                            ? `${process.env.REACT_APP_API_FLAGS}/64x48/${customerCountry.flag}.png`
                            : ""
                    }
                    alt="flag"
                    className="h-4 w-4 mr-2"
                />
                <span className="text-sm text-[#64748b]"> {customerCountry?.name || t("default.select_country")} </span>
            </button>
            {renderDropdown}
            <input
                type="hidden"
                {...register(props.name)}
                value={customerCountry?.name || ""}
            />
        </div>
    );
}

import SignOutButton from "./SignOutButton";
import Flags from "./Flags";
import CloseButton from "./CloseButton";
import { useMassage } from "../provider/massage";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { IconCreateTicket } from "./Icons";
import AdminHeader from "./AdminHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Header() {
  const { setShowPopupSignOut } = useMassage();
  const navigation = useNavigate()

  const location = useLocation();
  const isStartScreen = location.pathname === "/";
  const isAdminPage = location.pathname === "/admin";
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const currentRouter = location.pathname;

  useEffect(() => {
    let lastScrollTop = 0;
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        setIsScrollingDown(true);
      } else {
        setIsScrollingDown(false);
      }
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (isAdminPage) return <AdminHeader />;


  return (
    <div
      className={`fixed flex top-0 w-full bg-[#27636d] px-2 transition-transform duration-300 justify-end z-50 ${isScrollingDown ? "-translate-y-full" : "translate-y-0"
        }`}
    >
      <button className="text-white bg-transparent mt-[0.6rem] mr-2 hover:bg-[#529A98] rounded text-sm w-8 h-8 flex justify-center items-center cursor-pointer " onClick={() => navigation('admin')}>
        <FontAwesomeIcon icon={faUserAlt} />
      </button>
      <a
        href="https://360solved.youtrack.cloud/form/d1cb25a6-7bb1-420d-9797-3b735c6556a4"
        target="_blank"
        rel="noreferrer"
        className="text-white bg-transparent mt-2 hover:bg-[#529A98] rounded text-sm w-8 h-8 flex justify-center items-center cursor-pointer"
      >
        <IconCreateTicket />
      </a>
      <Flags />
      {currentRouter !== "/" && <CloseButton />}
      {isStartScreen && (
        <SignOutButton action={() => setShowPopupSignOut(true)} />
      )}
    </div>
  );
}

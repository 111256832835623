import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  TMassage,
  TMassageOption,
  TPrice,
  TCountry,
} from "./types";
import api from "../../services/api";
import { countries } from "../../utils/countries";
import { countriesSelect, TCountrySelect } from "../../utils/countriesSelect"
import { MassageI } from "../../types";
import axios from "axios";
import { generateUUID } from "../../utils/uuid";

type TMassageProps = {
  loading: boolean;
  step: number;
  massageRequest: TMassage | undefined;
  pressure: number;
  massages: TMassageOption[] | undefined;
  prices: TPrice[] | undefined;
  showPopupClose: boolean;
  showPopupGroup: boolean;
  groupState: boolean;
  // bookingInfo: TBookingInfo | undefined;
  // setBookingInfo: Dispatch<SetStateAction<TBookingInfo | undefined>>;
  setShowPopupClose: Dispatch<SetStateAction<boolean>>;
  setShowPopupGroup: Dispatch<SetStateAction<boolean>>;
  setGroupState: Dispatch<SetStateAction<boolean>>;
  setPressure: Dispatch<SetStateAction<number>>;
  setMassageRequest: Dispatch<SetStateAction<TMassage | undefined>>;
  setStep: Dispatch<SetStateAction<number>>;
  showPopupSignOut: boolean;
  setShowPopupSignOut: Dispatch<SetStateAction<boolean>>;
  countrySelected: TCountry;
  setCountrySelected: Dispatch<SetStateAction<TCountry>>;
  optionSelected: string | undefined;
  setOptionSelected: Dispatch<SetStateAction<string | undefined>>;
  // submitMassage: (data: TMassage) => Promise<any>;
  cancelMassage: (data: TMassage) => Promise<any>;
  submitCheckin: (data: TMassage) => Promise<any>;
  getMassages: () => Promise<any>;
  getSocialOptions: () => Promise<any>;
  allergyTypes: string[];
  customerCountry: TCountrySelect;
  setCustomerCountry: Dispatch<SetStateAction<TCountrySelect>>;
};

const MassageContext = createContext<TMassageProps | undefined>(undefined);

export function MassageProvider({
  children,
  ...rest
}: {
  children: ReactNode;
}) {

  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [massageRequest, setMassageRequest] = useState<TMassage>();
  const [pressure, setPressure] = useState<number>(3);
  const [massages, setMassages] = useState<MassageI[]>();
  const initialized = useRef(false);
  const [prices, setPrices] = useState<TPrice[]>();
  const [showPopupClose, setShowPopupClose] = useState(false);
  const [groupState, setGroupState] = useState(false);
  const [showPopupGroup, setShowPopupGroup] = useState(true);
  const [showPopupSignOut, setShowPopupSignOut] = useState(false);
  const [optionSelected, setOptionSelected] = useState<string>();
  const [customerCountry, setCustomerCountry] = useState<TCountrySelect>(countriesSelect[0]);
  const [countrySelected, setCountrySelected] = useState<TCountry>(
    countries[0]
  );
  // const [bookingInfo, setBookingInfo] = useState<TBookingInfo>();

  const getMassagesOptions = useCallback(async () => {
    try {
      const res = await getMassages();
      setMassages(res);
    } catch { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!initialized.current) {
      getMassagesOptions();
      initialized.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitCheckin = useCallback(async (data: any) => {
    try {
      const checkinObj = {
        checkinId: data.id,
        bodyParts: data.bodyParts,
        bodyPartsAvoid: data.bodyPartsAvoid,
        condition: data.condition,
        description: data.description,
        duration: data.duration,
        hasAvailability: data.hasAvailability,
        hasPain: data.hasPain,
        isConfirm: data.isConfirm,
        massage: data.massage ?? "Escolha depois",
        name: data.name,
        observation: data.observation,
        origin: data.origin,
        phone: data.phone,
        pressure: data.pressure,
        price: data.price,
        shopId: "AUREA",
        // suggestedTime: data.suggestedTime,
        address: data?.address,
        postalCode: data?.postalCode,
        country: data?.country,
        profession: data?.profession,
        email: data.email,
        clinicalData: data.clinicalData ?? "",
        birthday: data.birthday,
        consent: data.consent ?? false,
        referrralSource: data.referrralSource
      };

      await axios.post(`${process.env.REACT_APP_AMPLIFY_BACKEND}/checkin`, checkinObj);
    } catch (e) {
      throw new Error(`Error: ${e}`);
    } finally {
      setLoading(false);
    }
  }, []);

  // const submitMassage = useCallback(async (data: any) => {

  //   setLoading(true);
  //   try {
  //     const response = await api.post(`/`, data);
  //     setPrices(response.data.prices);
  //     return response.data;
  //   } catch (e) {
  //     throw new Error(`Error: ${e}`);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, []);


  const cancelMassage = useCallback(async (data: any) => {

    setLoading(true);
    try {
      const cancelObj = {
        cancelId: data.id ?? generateUUID(),
        name: data.name ?? "",
        details: data.details ?? "",
        duration: data.duration ?? "",
        group: data.group ?? false,
        hasBooking: data.hasBooking ?? false,
        hasPain: data.hasPain ?? false,
        massage: data.massage ?? "",
        motive: data.motive,
        observation: data.observation ?? "",
        origin: data.origin ?? "",
        phone: data.phone ?? "",
        pressure: data.pressure ?? "",
        price: data.price ?? "",
        bodyParts: data.bodyParts ?? [],
        bodyPartsAvoid: data.bodyPartsAvoid ?? [],
        shopId: "AUREA",
        address: data.address ?? "",
        postalCode: data.postalCode ?? "",
        country: data.country ?? "",
        profession: data.profession ?? "",
        email: data.email ?? "",
        clinicalData: data.clinicalData ?? [],
        birthday: data.birthday ?? "",
        consent: data.consent ?? false
      }

      const response = await axios.post(`${process.env.REACT_APP_AMPLIFY_BACKEND}/cancel-checkin`, cancelObj);
      setPrices(response.data.prices);
      return response.data;
    } catch (e) {
      throw new Error(`Error: ${e}`);
    } finally {
      setLoading(false);
    }
  }, []);


  const getMassages = useCallback(async (): Promise<MassageI[]> => {
    setLoading(true);

    try {
      const response = await api.get(`${process.env.REACT_APP_AMPLIFY_BACKEND}/massages`)
      return response.data.data;
    } catch (e) {
      throw new Error(`Error: ${e}`);
    } finally {
      setLoading(false);
    }
  }, []);

  const getSocialOptions = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`${process.env.REACT_APP_AMPLIFY_BACKEND}/sources`)

      return response.data;
    } catch (e) {
      throw new Error(`Error: ${e}`);
    } finally {
      setLoading(false);
    }
  }, []);

  const value = useMemo(() => {

    const allergyTypes = [
      "pathologies",
      "trauma_injuries_fractures",
      "surgeries",
      "gestation",
      "no_selection"
    ];

    return {
      loading,
      step,
      massageRequest,
      pressure,
      massages,
      prices,
      showPopupClose,
      setShowPopupClose,
      getMassages,
      setPressure,
      setMassageRequest,
      setStep,
      // submitMassage,
      // bookingInfo,
      // setBookingInfo,
      showPopupSignOut,
      setShowPopupSignOut,
      countrySelected,
      setCountrySelected,
      optionSelected,
      setOptionSelected,
      getSocialOptions,
      showPopupGroup,
      setShowPopupGroup,
      groupState,
      setGroupState,
      allergyTypes,
      customerCountry,
      setCustomerCountry,
      submitCheckin,
      cancelMassage
    };
  }, [
    loading,
    step,
    massageRequest,
    pressure,
    massages,
    prices,
    showPopupClose,
    setShowPopupClose,
    getMassages,
    setPressure,
    setMassageRequest,
    setStep,
    // submitMassage,
    // bookingInfo,
    // setBookingInfo,
    showPopupSignOut,
    setShowPopupSignOut,
    countrySelected,
    setCountrySelected,
    customerCountry,
    setCustomerCountry,
    optionSelected,
    setOptionSelected,
    getSocialOptions,
    showPopupGroup,
    setShowPopupGroup,
    groupState,
    setGroupState,
    submitCheckin,
    cancelMassage
  ]);

  return (
    <MassageContext.Provider value={value} {...rest}>
      {children}
    </MassageContext.Provider>
  );
}

export function useMassage() {
  const context = useContext(MassageContext);
  if (context === undefined) {
    throw new Error("useMassage must be used within a MassageProvider");
  }
  return context;
}

import "./App.css";
import ProtectedRoute from "./router/ProtectedRoute";
import { I18nextProvider } from "react-i18next";
import { useTranslation } from "react-i18next";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { type AuthUser } from "aws-amplify/auth";
import { Amplify } from "aws-amplify";
import { type UseAuthenticator } from "@aws-amplify/ui-react-core";
import awsmobile from "./aws-exports";
import Header from "./components/Header";
import Cancelling from "./components/Cancelling";
import PopupSignOut from "./components/PopupSignOut";
import { useMassage } from "./provider/massage";

Amplify.configure(awsmobile);

type AppProps = {
  signOut?: UseAuthenticator["signOut"];
  user?: AuthUser;
};

const App: React.FC<AppProps> = ({ signOut, user }) => {
  const { showPopupClose, showPopupSignOut, setShowPopupSignOut } =
    useMassage();
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();

  return (
    <I18nextProvider i18n={i18n}>
      {showPopupClose && <Cancelling />}
      {showPopupSignOut && (
        <PopupSignOut
          actionClose={() => setShowPopupSignOut(!showPopupSignOut)}
          actionSignOut={() => signOut?.()}
        />
      )}
      <Header />
      <ProtectedRoute />
    </I18nextProvider>
  );
};

export default withAuthenticator(App);

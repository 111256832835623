import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconArrowDown, IconSearch } from "./Icons";
import { useMassage } from "../provider/massage";
import { SelectOptionProps } from "../types";

export function SelectOption(props: SelectOptionProps) {
  const { t } = useTranslation();
  const { optionSelected, setOptionSelected, massageRequest } = useMassage();
  const [search, setSearch] = useState<string>();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (massageRequest?.referralSource && !optionSelected) {
      setOptionSelected(massageRequest.referralSource);
    }
  }, [massageRequest, optionSelected, setOptionSelected]);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const filteredOptions = useMemo(() => {
    if (!search) return props.options;
    return props.options.filter((item) =>
      item.source.toLowerCase().includes(search.toLowerCase())
    );
  }, [props.options, search]);

  return (
    <div className="flex h-10 w-full" ref={dropdownRef}>
      <button
        id="dropdown-phone-button"
        onClick={toggleDropdown}
        type="button"
        className="text-start appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
      >
        {optionSelected || t("default.choose_an_option")}
        <div className="absolute top-1/2 right-1 -translate-y-1/2 z-10">
          <IconArrowDown height="6px" />
        </div>
      </button>

      {isDropdownOpen && (
        <div
          id="dropdown-phone"
          className="z-10 bg-white absolute divide-y divide-slate-100 rounded-lg max-h-[200px] mt-[40px] shadow-xl w-full max-w-[370px]"
        >
          <ul
            className="py-2 text-sm text-slate-700 overflow-y-auto max-h-[200px]"
            aria-labelledby="dropdown-phone-button"
          >
            <li className="inline-flex z-99 w-full px-4 py-2 text-sm text-slate-700">
              <div className="inline-flex items-center border border-slate-200 p-2 w-full rounded">
                <IconSearch />
                <input
                  type="search"
                  className="outline-none w-full ml-2"
                  value={search || ""}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </li>

            {filteredOptions.length &&
              filteredOptions.map((option, i) => (
                <li
                  key={i}
                  className="flex z-99 w-full py-2 text-sm text-slate-700 hover:bg-slate-200"
                >
                  <button
                    onClick={() => {
                      toggleDropdown();
                      setOptionSelected(option.source);
                      props.action();
                    }}
                    type="button"
                    className="inline-flex z-99 w-full px-4 py-2 text-sm text-slate-700 hover:bg-slate-200"
                  >
                    <div className="inline-flex items-center">
                      {option.source}
                    </div>
                  </button>
                </li>
              ))}
            <li className="flex z-99 w-full py-2 text-sm text-slate-700 hover:bg-slate-200">
              <button
                onClick={() => {
                  toggleDropdown();
                  setOptionSelected("OUTRO");
                  props.action();
                }}
                type="button"
                className="inline-flex z-99 w-full px-4 py-2 text-sm text-slate-700 hover:bg-slate-200"
              >
                <div className="inline-flex items-center">OUTRO</div>
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

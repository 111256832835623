import React, { useState, useEffect, useRef } from 'react';
import { ProfessionalSelectProps } from '../types';

const ProfessionalSelect: React.FC<ProfessionalSelectProps> = ({
    professionalsData,
    onChange,
    storeSelected,
    preSelectedStore,
    preSelectedProfessional,
}) => {
    const [selectedStore, setSelectedStore] = useState<string | null>(preSelectedStore || null);
    const [selectedProfessional, setSelectedProfessional] = useState<string | null>(preSelectedProfessional || null);
    const [options, setOptions] = useState<string[]>([]);
    const [open, setOpen] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const storeOptions = Array.from(new Set(professionalsData.map(professional => professional.store)));
        if (selectedStore) {
            const filteredProfessionals = professionalsData
                .filter(professional => professional.store === selectedStore)
                .map(professional => professional.name);
            setOptions(['Limpar Seleção', ...filteredProfessionals]);
        } else {
            setOptions(['Limpar Seleção', ...storeOptions]);
        }
    }, [selectedStore, professionalsData]);

    const handleChange = (value: string) => {
        if (value === 'Limpar Seleção') {
            setSelectedStore(null);
            setSelectedProfessional(null);
            onChange('');
        } else if (!selectedStore) {
            storeSelected(value);
            setSelectedStore(value);
        } else {
            setSelectedProfessional(value);
            onChange(value);
        }
        setOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative w-[8rem]" ref={selectRef}>
            <button
                type="button"
                className="relative w-full cursor-default rounded-md bg-[#F8F7F6] py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
                aria-haspopup="listbox"
                aria-expanded={open}
                onClick={() => setOpen((prev) => !prev)}
            >
                <span className="block truncate">{selectedProfessional || (selectedStore ? 'Choose Professional' : 'Choose Store')}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M10.53 3.47a.75.75 0 0 0-1.06 0L6.22 6.72a.75.75 0 0 0 1.06 1.06L10 5.06l2.72 2.72a.75.75 0 1 0 1.06-1.06l-3.25-3.25Zm-4.31 9.81 3.25 3.25a.75.75 0 0 0 1.06 0l3.25-3.25a.75.75 0 1 0-1.06-1.06L10 14.94l-2.72-2.72a.75.75 0 0 0-1.06 1.06Z" clipRule="evenodd" />
                    </svg>
                </span>
            </button>

            {open && (
                <>
                    <div className="absolute z-10" onClick={() => setOpen(false)} />
                    <ul className="absolute z-30 mt-1 max-h-56 w-full overflow-auto rounded-md bg-[#F8F7F6] py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {options.map((option) => (
                            <li
                                key={option}
                                className={`relative z-10 h-8 cursor-default select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-gray-300 ${selectedProfessional === option ? 'font-semibold' : 'font-normal'}`}
                                onClick={() => handleChange(option)}
                                role="option"
                            >
                                <span className="block truncate">
                                    {option}
                                </span>
                                {selectedProfessional === option && (
                                    <span className="absolute z-10 inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                )}
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </div>
    );
};

export default ProfessionalSelect;

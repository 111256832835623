import React from "react";
import { LabelProps } from "../types";

const Label: React.FC<LabelProps> = ({
  children,
  className = "",
  ...props
}) => {
  return (
    <label className={`font-bold text-gray-700 ${className}`} {...props}>
      {children}
    </label>
  );
};

export default Label;

import React from "react";
import "react-tooltip/dist/react-tooltip.css";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { TProps } from "../types";

const PopupSignOut: React.FC<TProps> = ({ actionClose, actionSignOut }) => {
  const { t } = useTranslation();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full backdrop-blur-sm bg-black/30"
    >
      <div className="relative p-4 w-full max-w-md max-h-full flex items-center justify-center">
        <div className="relative bg-white rounded-lg shadow flex flex-col">
          <h3 className="font-bold text-gray-600 block text-center text-lg m-6">
            {t("default.modal_signout_title")}
          </h3>
          <div className="flex item-center">
            <button
              onClick={actionClose}
              className="w-full p-4 border border-transparent text-md font-bold text-gray-900 border-t-gray-300 border-r-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              {t("default.cancel")}
            </button>
            <button
              onClick={() => {
                actionSignOut();
                actionClose();
              }}
              className="w-full p-4 border border-transparent text-md font-bold text-gray-900 border-t-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              {t("default.sign_out")}
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default PopupSignOut;

import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import i18n from "../locales/i18n";
import { IconArrowDown } from "./Icons";
import { motion } from "framer-motion";

export default function Flags() {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleLanguageChange = useCallback(
    async (language: string) => {
      await i18n.changeLanguage(language);
      setDropdownOpen(false);
    },
    [setDropdownOpen]
  );

  const selectedLanguage = useMemo(() => {
    return languages.find((item) => i18n.language === item.code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <div className="z-50 mx-2 mt-0.5 text-gray-200 bg-[#255D67] hover:text-gray-100 rounded-lg max-w-28 justify-center items-center">
      <div className="rounded bg-green-850 relative pb-2 flex flex-col w-full">
        <Link
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className="flex items-center justify-start mt-2 px-2"
          to="#"
        >
          <span className="flex items-center justify-center gap-2 font-medium duration-300 ease-in-out hover:text-primary text-lg px-2">
            <img
              src={selectedLanguage?.flag}
              alt="flag"
              className="h-4 w-4 mr-2"
            />
            {selectedLanguage?.code.toUpperCase()}
          </span>

          <IconArrowDown />
        </Link>

        {dropdownOpen && (
          <motion.ul
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="rounded-lg mt-10 p-4 absolute flex flex-col gap-5 border-stroke dark:border-strokedark justify-startl w-full z-50 bg-[#255D67]"
          >
            {languages.map((lang, i) => {
              if (lang === selectedLanguage) return null;
              return (
                <li key={i}>
                  <Link
                    to="#"
                    onClick={() => handleLanguageChange(lang.code)}
                    className="pt-2 flex items-center gap-3.5 font-medium duration-300 ease-in-out hover:text-primary text-base"
                  >
                    <img src={lang.flag} alt="flag" className="h-4 w-4" />
                    {lang.code.toUpperCase()}
                  </Link>
                </li>
              );
            })}
          </motion.ul>
        )}
      </div>
    </div>
  );
}

const languages = [
  {
    name: "Portugal",
    code: "pt",
    flag: `https://flagcdn.com/64x48/pt.png`,
  },
  {
    name: "United States",
    code: "en",
    flag: `https://flagcdn.com/64x48/us.png`,
  },
  {
    name: "Spain",
    code: "es",
    flag: `https://flagcdn.com/64x48/es.png`,
  },
  {
    name: "France",
    code: "fr",
    flag: `https://flagcdn.com/64x48/fr.png`,
  },
  {
    name: "Italy",
    code: "it",
    flag: `https://flagcdn.com/64x48/it.png`,
  },
  {
    name: "Germany",
    code: "de",
    flag: `https://flagcdn.com/64x48/de.png`,
  },
  {
    name: "Arabia Saudi",
    code: "ar",
    flag: `https://flagcdn.com/64x48/sa.png`,
  },
  {
    name: "China",
    code: "cn",
    flag: `https://flagcdn.com/64x48/cn.png`,
  },
];

import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import "./PressureSelector.css";
import { useMassage } from "../provider/massage";
import { IconAlert, IconClose } from "./Icons";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const PressureSelector: React.FC = () => {
  const { t } = useTranslation();
  const { setMassageRequest, pressure, setPressure, massageRequest } =
    useMassage();
  const [showPopup, setShowPopup] = useState(false);
  const prevPressureRef = useRef<number>(3);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPressure(Number(e.target.value));
      setMassageRequest((prev) => ({
        ...prev!,
        pressure: Number(e.target.value),
      }));
    },
    [setMassageRequest, setPressure]
  );

  useEffect(() => {
    if (
      (prevPressureRef.current === 3 && pressure === 2) ||
      (prevPressureRef.current === 3 && pressure === 1)
    ) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }

    prevPressureRef.current = pressure;
  }, [pressure, prevPressureRef]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const PopupAlert = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full backdrop-blur-sm bg-black/30"
    >
      <div className="relative p-4 w-full max-w-md max-h-full flex items-center justify-center overflow-x-hidden">
        <div className="relative bg-white rounded-lg shadow flex">
          <button
            onClick={handleClosePopup}
            className="absolute top-3 end-2.5 text-gray-700 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            data-modal-hide="popup-modal"
          >
            <IconClose />
            <span className="sr-only">Close modal</span>
          </button>
          <div className="p-4 md:p-5 text-center">
            <IconAlert />
            <h3 className="font-bold text-gray-700 block text-center text-lg mb-6">
              {t("default.modal_close_title")}
            </h3>
          </div>
        </div>
      </div>
    </motion.div>
  );

  return (
    <div className="pressure-selector-container">
      {showPopup && massageRequest?.hasPain && <PopupAlert />}
      <div className="info-icon-container">
        <span data-tooltip-id="info-tooltip" className="info-icon">
          i
        </span>
      </div>
      <h3 className="title">{t("default.select_pressure")}</h3>
      <div className="labels">
        <span>{t("default.low")}</span>
        <span>{t("default.high")}</span>
      </div>
      <input
        type="range"
        min="1"
        max="5"
        defaultValue={pressure}
        onChange={(e) => {
          prevPressureRef.current = pressure;
          handleChange(e);
        }}
        className="slider"
      />
      <div className="values">
        {[1, 2, 3, 4, 5].map((num) => (
          <span key={num} className={pressure === num ? "active" : ""}>
            {num}
          </span>
        ))}
      </div>
      <Tooltip id="info-tooltip" place="top">
        {t("default.you_can_change_this_later")}
      </Tooltip>
    </div>
  );
};

export default PressureSelector;

import { useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IconSearch } from "./Icons";
import { countries } from "../utils/countries";
import { useMassage } from "../provider/massage";
import { SelectCountryCodeProps } from "../types";

export function SelectCountryCode(props: SelectCountryCodeProps) {
  const { register } = useFormContext();
  const { t } = useTranslation();
  const { countrySelected, setCountrySelected } = useMassage();
  const [search, setSearch] = useState<string>();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredCountries = useMemo(() => {
    if (!search) return countries;
    return countries.filter((country) =>
      country.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [search]);


  const renderDropdown = useMemo(() => {
    return (
      <div ref={dropdownRef} className="absolute z-10 bg-white divide-y divide-slate-100 rounded-lg max-h-[300px] w-full">
        <ul className="py-2 text-sm text-slate-700 overflow-y-auto max-h-[300px]">
          <li className="inline-flex z-99 w-full px-4 py-2 text-sm text-slate-700">
            <div className="inline-flex items-center border border-slate-200 p-2 w-full rounded">
              <IconSearch />
              <input
                type="search"
                className="outline-none ml-2 w-full"
                value={search || ""}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </li>
          {filteredCountries.length ? (
            filteredCountries.map((country, i) => (
              <li key={i} className="inline-flex z-99 w-full px-4 py-2 text-sm text-slate-700 hover:bg-slate-200">
                <button
                  type="button"
                  onClick={() => {
                    setIsDropdownOpen(false);
                    setCountrySelected(country);
                  }}
                  className="inline-flex z-99 w-full px-4 py-2 text-sm text-slate-700 hover:bg-slate-200"
                  role="menuitem"
                >
                  <div className="inline-flex items-center">
                    <img
                      src={`${process.env.REACT_APP_API_FLAGS}/64x48/${country.flag}.png`}
                      alt="flag"
                      className="h-3 w-4 mr-2"
                    />
                    {country.name}
                  </div>
                </button>
              </li>
            ))
          ) : (
            <li className="inline-flex z-99 w-full px-4 py-2 text-sm text-slate-700 dark:text-slate-200">
              <div className="inline-flex items-center justify-center p-2 w-full">
                <span>{t("default.no_results")}</span>
              </div>
            </li>
          )}
        </ul>
      </div>
    );
  }, [filteredCountries, search, setCountrySelected, t]);

  return (
    <div className="flex items-center relative w-full bg-white dark:bg-form-input">
      <div className="w-1/4">
        <button
          onClick={() => setIsDropdownOpen((prev) => !prev)}
          className="flex flex-row justify-center items-center focus:outline-none relative w-full appearance-none rounded border border-stroke bg-slate-100 py-3 px-5 outline-none transition focus:border-primary active:border-primary"
          type="button"
        >
          <img
            src={`${process.env.REACT_APP_API_FLAGS}/64x48/${countrySelected.flag}.png`}
            alt="flag"
            className="h-4 w-4"
          />
          <span className="text-[#64748b]">{countrySelected.code}</span>
        </button>
        {isDropdownOpen && renderDropdown}
      </div>
      <div className="w-3/4">
        <input
          pattern={countrySelected?.regex || "[0-9]{3}-[0-9]{3}-[0-9]{4}"}
          placeholder={countrySelected.placeholder}
          id={props.name}
          required
          className="focus:outline-none relative w-full appearance-none rounded border border-stroke py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
          {...register(props.name)}
          {...props}
        />
      </div>
    </div>
  );
}

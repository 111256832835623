import React, { useState, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DatePickerProps } from '../../types';


const DatePicker: React.FC<DatePickerProps> = ({ name }) => {
    const { setValue, watch, register } = useFormContext();
    const { t } = useTranslation();
    const selectedDate = watch(name);
    const [step, setStep] = useState<number>(1);
    const [selectedYear, setSelectedYear] = useState<number | null>(null);
    const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
    const [selectedDay, setSelectedDay] = useState<number | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const years = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);
    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    const days = selectedMonth
        ? Array.from({ length: new Date(selectedYear || 2023, selectedMonth, 0).getDate() }, (_, i) => i + 1)
        : [];

    const handleYearSelect = (year: number) => {
        setSelectedYear(year);
        setStep(2);
    };

    const handleMonthSelect = (month: number) => {
        setSelectedMonth(month);
        setStep(3);
    };


    const handleDaySelect = (day: number) => {
        setSelectedDay(day);
        if (selectedYear && selectedMonth) {
            const formattedDate = `${day.toString().padStart(2, '0')}/${selectedMonth.toString().padStart(2, '0')}/${selectedYear}`;
            setValue(name, formattedDate);
        }
        setStep(1);
        setIsOpen(false);
    };

    const wrapperRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    register(name, { required: t("default.required_field") }); 

    return (
        <div className="relative">
            <div
                onClick={() => setIsOpen(!isOpen)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#235B66] focus:border-[#235B66] cursor-pointer"
            >
                {selectedDate ? selectedDate : 'Selecionar Data'}
            </div>

            {isOpen && (
                <>
                    {/* Overlay */}
                    <div className="fixed inset-0 bg-black bg-opacity-50 z-10" onClick={() => setIsOpen(false)} />

                    {/* DatePicker Popup */}
                    <div className="fixed inset-0 z-20 flex items-center justify-center" ref={wrapperRef}>
                        <div className="bg-white border shadow-lg rounded-xl p-4 sm:w-3/4 md:w-3/4 max-h-[400px] overflow-y-auto transition-transform duration-300 opacity-100 scale-100">
                            {step === 1 && (
                                <div className="grid grid-cols-4 gap-2">
                                    {years.map(year => (
                                        <button
                                            key={year}
                                            onClick={() => handleYearSelect(year)}
                                            className="p-2 text-gray-800 bg-gray-100 rounded hover:bg-blue-200"
                                        >
                                            {year}
                                        </button>
                                    ))}
                                </div>
                            )}
                            {step === 2 && (
                                <div className="grid grid-cols-4 gap-2">
                                    {months.map((_, index) => (
                                        <button
                                            key={index + 1}
                                            onClick={() => handleMonthSelect(index + 1)}
                                            className="p-2 text-gray-800 bg-gray-100 rounded hover:bg-blue-200"
                                        >
                                            {t(`default.months.${index}`)}
                                        </button>
                                    ))}
                                </div>
                            )}
                            {step === 3 && (
                                <div>
                                    <div className="grid grid-cols-7 gap-2 mb-2">
                                        {Array.from({ length: 7 }, (_, i) => (
                                            <span key={i + 1} className="text-sm font-semibold text-gray-500">
                                                {t(`default.weekdays.${i}`)}
                                            </span>
                                        ))}
                                    </div>
                                    <div className="grid grid-cols-7 gap-2">
                                        {days.map(day => (
                                            <button
                                                key={day}
                                                onClick={() => handleDaySelect(day)}
                                                className="p-2 text-gray-800 bg-gray-100 rounded hover:bg-blue-200"
                                            >
                                                {day}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {step === 3 && (
                                <div className="mt-4 text-center">
                                    <p>
                                        {t('default.selectedDate')}: {selectedDay}/{selectedMonth}/{selectedYear}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default DatePicker;

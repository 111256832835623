import SvgWrapperAdmin from "./SvgWrapperAdmin";
import BodySvgFront from "./BodySvgFront";
import BodySvgBack from "./BodySvgBack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconClose } from "../Icons";
import { BodyDiagramProps } from "../../types";

export default function BodyDiagram({
  valueFront,
  valueBack,
  onChangeFront,
  onChangeBack,
  valueAvoidFront,
  onChangeAvoidFront,
  valueAvoidBack,
  onChangeAvoidBack,
  onClose
}: BodyDiagramProps) {
  const { t } = useTranslation();
  const [focus, setFocus] = useState(true);

  const onPartClick = (name: string) => {
    const indexFront = valueFront.indexOf(name);
    const indexAvoidFront = valueAvoidFront.indexOf(name);

    if (indexFront !== -1) {
      const newFront = [...valueFront];
      newFront.splice(indexFront, 1);
      onChangeFront(newFront);
    } else if (indexAvoidFront !== -1) {
      const newAvoidFront = [...valueAvoidFront];
      newAvoidFront.splice(indexAvoidFront, 1);
      onChangeAvoidFront(newAvoidFront);
    } else {
      if (focus) {
        onChangeFront([...valueFront, name]);
      } else {
        onChangeAvoidFront([...valueAvoidFront, name]);
      }
    }
  };

  const onPartClickBack = (name: string) => {
    const indexBack = valueBack.indexOf(name);
    const indexAvoidBack = valueAvoidBack.indexOf(name);

    if (indexBack !== -1) {
      const newBack = [...valueBack];
      newBack.splice(indexBack, 1);
      onChangeBack(newBack);
    } else if (indexAvoidBack !== -1) {
      const newAvoidBack = [...valueAvoidBack];
      newAvoidBack.splice(indexAvoidBack, 1);
      onChangeAvoidBack(newAvoidBack);
    } else {
      if (focus) {
        onChangeBack([...valueBack, name]);
      } else {
        onChangeAvoidBack([...valueAvoidBack, name]);
      }
    }
  };

  return (
    <SvgWrapperAdmin>
      <div className="flex flex-col items-center mb-4">
        <div className="flex items-center justify-center mb-2 space-x-4">
          <button
            className="flex items-center justify-center ml-5"
          >
            <div className="text-center ml-20 pl-2">
              <div className="flex items-center justify-center">
                {t("default.focus")}
                <div className="h-2 w-2 rounded-full bg-[#529A98] ml-1" />
              </div>
            </div>
          </button>

          <button
            className={`flex items-center justify-center p-2 min-w-40 ${!focus ? "rounded-full shadow-md" : ""}`}
          >
            <div className="text-center -ml-2">
              <div className="flex items-center justify-center">
                {t("default.avoid")}
                <div className="h-2 w-2 rounded-full bg-[#fca5a5] ml-1" />
              </div>
            </div>
          </button>

          <div className="flex items-center justify-center">
            <button
              className="bg-[#CEF8FF] text-black h-8 w-8 p-2 rounded-full hover:bg-[#EBEDF0] transition-opacity duration-200"
              onClick={onClose}
            >
              <IconClose />
            </button>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:justify-center w-full">
          <div className="flex flex-col items-center mb-6 md:mb-0 md:mr-4">
            <p className="font-bold text-gray-700 text-center text-base">
              {t("default.body_front")}
            </p>
            <div className="flex">
              <BodySvgFront
                selected={valueFront}
                selectedAvoid={valueAvoidFront}
                onPartClick={onPartClick}
              />
            </div>
          </div>

          <div className="flex flex-col items-center mb-6 md:mb-0 md:mr-4">
            <div className="flex flex-col items-center">
              <p className="font-bold text-gray-700 text-center text-base mb-3">
                {t("default.body_back")}
              </p>
              <div className="flex relative bottom-1.5">
                <BodySvgBack
                  selected={valueBack}
                  selectedAvoid={valueAvoidBack}
                  onPartClick={onPartClickBack}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SvgWrapperAdmin>
  );
}

export type TCountrySelect = {
  name: string;
  code: string;
  flag: string;
  regex: string;
  placeholder: string;
};

export const countriesSelect: TCountrySelect[] = [
  {
    name: "Portugal",
    code: "+351",
    flag: "pt",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "912 345 678",
  },
  {
    name: "Canada",
    code: "+1",
    flag: "ca",
    regex:
      "^1[\\s\\-]?(204|226|236|249|250|289|306|343|365|367|403|416|418|431|437|438|450|506|514|519|548|579|581|587|604|613|639|647|672|705|709|742|778|780|807|819|825|867|873|902|905)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(204) 234-5678",
  },
  {
    name: "France",
    code: "+33",
    flag: "fr",
    regex:
      "^\\d{1}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "06 12 34 56 78",
  },
  {
    name: "Spain",
    code: "+34",
    flag: "es",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "612 34 56 78",
  },
  {
    name: "United Kingdom",
    code: "+44",
    flag: "gb",
    regex: "^\\d{4}[\\s\\-]?\\d{6}$",
    placeholder: "07400 123456",
  },
  {
    name: "United States",
    code: "+1",
    flag: "us",
    regex: "^1[\\s\\-]?(\\d[\\s\\-]?)?\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(123) 456-7890",
  },
  {
    name: "Brazil",
    code: "+55",
    flag: "br",
    regex: "^\\d{2}[\\s\\-]?\\d{5}[\\s\\-]?\\d{4}$",
    placeholder: "(11) 12345-6789",
  },
  {
    name: "Germany",
    code: "+49",
    flag: "de",
    regex: "^\\d{2,4}[\\s\\-]?\\d{6,10}$",
    placeholder: "01512 3456789",
  },
  {
    name: "Italy",
    code: "+39",
    flag: "it",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "312 345 6789",
  },
  {
    name: "Netherlands",
    code: "+31",
    flag: "nl",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "06 12345678",
  },
  {
    name: "Belgium",
    code: "+32",
    flag: "be",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "0486 12 34 56",
  },
  {
    name: "Sweden",
    code: "+46",
    flag: "se",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "070-123 45 67",
  },
  {
    name: "Afghanistan",
    code: "+93",
    flag: "af",
    regex: "^\\d{10}$",
    placeholder: "071 123 4567",
  },
  {
    name: "Albania",
    code: "+355",
    flag: "al",
    regex: "^\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "066 212 3456",
  },
  {
    name: "Algeria",
    code: "+213",
    flag: "dz",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "0551 23 45 67",
  },
  {
    name: "Andorra",
    code: "+376",
    flag: "ad",
    regex: "^\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "312 345",
  },
  {
    name: "Angola",
    code: "+244",
    flag: "ao",
    regex: "^\\d{9}$",
    placeholder: "923 123 456",
  },
  {
    name: "Anguilla",
    code: "+1",
    flag: "ai",
    regex: "^1[\\s\\-]?(264)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(264) 235-1234",
  },
  {
    name: "Antigua and Barbuda",
    code: "+1",
    flag: "ag",
    regex: "^1[\\s\\-]?(268)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(268) 464-1234",
  },
  {
    name: "Argentina",
    code: "+54",
    flag: "ar",
    regex: "^\\d{2}[\\s\\-]?\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "011 15-2345-6789",
  },
  {
    name: "Armenia",
    code: "+374",
    flag: "am",
    regex: "^\\d{2}[\\s\\-]?\\d{6}$",
    placeholder: "077 123456",
  },
  {
    name: "Aruba",
    code: "+297",
    flag: "aw",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "560 1234",
  },
  {
    name: "Afghanistan",
    code: "+93",
    flag: "af",
    regex: "^\\d{10}$",
    placeholder: "071 123 4567",
  },
  {
    name: "Albania",
    code: "+355",
    flag: "al",
    regex: "^\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "066 212 3456",
  },
  {
    name: "Algeria",
    code: "+213",
    flag: "dz",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "0551 23 45 67",
  },
  {
    name: "Andorra",
    code: "+376",
    flag: "ad",
    regex: "^\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "312 345",
  },
  {
    name: "Angola",
    code: "+244",
    flag: "ao",
    regex: "^\\d{9}$",
    placeholder: "923 123 456",
  },
  {
    name: "Anguilla",
    code: "+1",
    flag: "ai",
    regex: "^1[\\s\\-]?(264)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(264) 235-1234",
  },
  {
    name: "Antigua and Barbuda",
    code: "+1",
    flag: "ag",
    regex: "^1[\\s\\-]?(268)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(268) 464-1234",
  },
  {
    name: "Argentina",
    code: "+54",
    flag: "ar",
    regex: "^\\d{2}[\\s\\-]?\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "011 15-2345-6789",
  },
  {
    name: "Armenia",
    code: "+374",
    flag: "am",
    regex: "^\\d{2}[\\s\\-]?\\d{6}$",
    placeholder: "077 123456",
  },
  {
    name: "Aruba",
    code: "+297",
    flag: "aw",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "560 1234",
  },
  {
    name: "Australia",
    code: "+61",
    flag: "au",
    regex: "^\\d{2}[\\s\\-]?\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "0412 345 678",
  },
  {
    name: "Austria",
    code: "+43",
    flag: "at",
    regex: "^\\d{4}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "0664 123456",
  },
  {
    name: "Azerbaijan",
    code: "+994",
    flag: "az",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "040 123 45 67",
  },
  {
    name: "Bahamas",
    code: "+1",
    flag: "bs",
    regex: "^1[\\s\\-]?(242)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(242) 359-1234",
  },
  {
    name: "Bahrain",
    code: "+973",
    flag: "bh",
    regex: "^\\d{3,4}[\\s\\-]?\\d{4}$",
    placeholder: "3600 1234",
  },
  {
    name: "Bangladesh",
    code: "+880",
    flag: "bd",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "01812 345678",
  },
  {
    name: "Barbados",
    code: "+1",
    flag: "bb",
    regex: "^1[\\s\\-]?(246)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(246) 250-1234",
  },
  {
    name: "Belarus",
    code: "+375",
    flag: "by",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "029 123 45 67",
  },
  {
    name: "Belize",
    code: "+501",
    flag: "bz",
    regex: "^\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "622 1234",
  },
  {
    name: "Benin",
    code: "+229",
    flag: "bj",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "90 01 23 45",
  },
  {
    name: "Bermuda",
    code: "+1",
    flag: "bm",
    regex: "^1[\\s\\-]?(441)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(441) 370-1234",
  },
  {
    name: "Bhutan",
    code: "+975",
    flag: "bt",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "1712 3456",
  },
  {
    name: "Bolivia",
    code: "+591",
    flag: "bo",
    regex: "^\\d{2}[\\s\\-]?\\d{7,8}$",
    placeholder: "71234567",
  },
  {
    name: "Bosnia and Herzegovina",
    code: "+387",
    flag: "ba",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "061 123 456",
  },
  {
    name: "Botswana",
    code: "+267",
    flag: "bw",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "71 123 456",
  },
  {
    name: "British Indian Ocean Territory",
    code: "+246",
    flag: "io",
    regex: "^\\d{4}$",
    placeholder: "3876",
  },
  {
    name: "British Virgin Islands",
    code: "+1",
    flag: "vg",
    regex: "^1[\\s\\-]?(284)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(284) 300-1234",
  },
  {
    name: "Brunei Darussalam",
    code: "+673",
    flag: "bn",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "712 3456",
  },
  {
    name: "Bulgaria",
    code: "+359",
    flag: "bg",
    regex: "^\\d{1}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "0876 123 456",
  },
  {
    name: "Burkina Faso",
    code: "+226",
    flag: "bf",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "70 12 34 56",
  },
  {
    name: "Burundi",
    code: "+257",
    flag: "bi",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{3}$",
    placeholder: "79 12 34 56",
  },
  {
    name: "Cambodia",
    code: "+855",
    flag: "kh",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "123 456 789",
  },
  {
    name: "Cameroon",
    code: "+237",
    flag: "cm",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "671 23 45 67",
  },
  {
    name: "Cape Verde",
    code: "+238",
    flag: "cv",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "991 12 34",
  },
  {
    name: "Cayman Islands",
    code: "+1",
    flag: "ky",
    regex: "^1[\\s\\-]?(345)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(345) 323-1234",
  },
  {
    name: "Central African Republic",
    code: "+236",
    flag: "cf",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "70 01 23 45",
  },
  {
    name: "Chad",
    code: "+235",
    flag: "td",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "63 01 23 45",
  },
  {
    name: "Chile",
    code: "+56",
    flag: "cl",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "09 6123 4567",
  },
  {
    name: "China",
    code: "+86",
    flag: "cn",
    regex: "^\\d{3}[\\s\\-]?\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "131 2345 6789",
  },
  {
    name: "Christmas Island",
    code: "+61",
    flag: "cx",
    regex: "^\\d{2}[\\s\\-]?\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "0412 345 678",
  },
  {
    name: "Cocos Islands",
    code: "+61",
    flag: "cc",
    regex: "^\\d{2}[\\s\\-]?\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "0412 345 678",
  },
  {
    name: "Colombia",
    code: "+57",
    flag: "co",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "312 3456789",
  },
  {
    name: "Comoros",
    code: "+269",
    flag: "km",
    regex: "^\\d{2}[\\s\\-]?\\d{3}$",
    placeholder: "321 23 45",
  },
  {
    name: "Congo",
    code: "+242",
    flag: "cg",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "06 612 34 56",
  },
  {
    name: "Cook Islands",
    code: "+682",
    flag: "ck",
    regex: "^\\d{5}$",
    placeholder: "12345",
  },
  {
    name: "Costa Rica",
    code: "+506",
    flag: "cr",
    regex: "^\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "6123 4567",
  },
  {
    name: "Croatia",
    code: "+385",
    flag: "hr",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3,4}$",
    placeholder: "091 123 4567",
  },
  {
    name: "Cuba",
    code: "+53",
    flag: "cu",
    regex: "^\\d{2}[\\s\\-]?\\d{6,7}$",
    placeholder: "05 1234567",
  },
  {
    name: "Curaçao",
    code: "+599",
    flag: "cw",
    regex: "^\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "9 518 1234",
  },
  {
    name: "Cyprus",
    code: "+357",
    flag: "cy",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "96 123456",
  },
  {
    name: "Czech Republic",
    code: "+420",
    flag: "cz",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "601 123 456",
  },
  {
    name: "Democratic Republic of the Congo",
    code: "+243",
    flag: "cd",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "0991 234 567",
  },
  {
    name: "Denmark",
    code: "+45",
    flag: "dk",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "12 34 56 78",
  },
  {
    name: "Djibouti",
    code: "+253",
    flag: "dj",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "77 83 00 01",
  },
  {
    name: "Dominica",
    code: "+1",
    flag: "dm",
    regex: "^1[\\s\\-]?(767)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(767) 225-1234",
  },
  {
    name: "Dominican Republic",
    code: "+1",
    flag: "do",
    regex: "^1[\\s\\-]?(809|829|849)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(809) 234-5678",
  },
  {
    name: "East Timor",
    code: "+670",
    flag: "tl",
    regex: "^\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "7721 2345",
  },
  {
    name: "Ecuador",
    code: "+593",
    flag: "ec",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "099 123 4567",
  },
  {
    name: "Egypt",
    code: "+20",
    flag: "eg",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4,5}$",
    placeholder: "0100 123 4567",
  },
  {
    name: "El Salvador",
    code: "+503",
    flag: "sv",
    regex: "^\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "7012 3456",
  },
  {
    name: "Equatorial Guinea",
    code: "+240",
    flag: "gq",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "222 11 1234",
  },
  {
    name: "Eritrea",
    code: "+291",
    flag: "er",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "07 123 4567",
  },
  {
    name: "Estonia",
    code: "+372",
    flag: "ee",
    regex: "^\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "5123 4567",
  },
  {
    name: "Ethiopia",
    code: "+251",
    flag: "et",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "091 123 4567",
  },
  {
    name: "Falkland Islands",
    code: "+500",
    flag: "fk",
    regex: "^\\d{5}$",
    placeholder: "51234",
  },
  {
    name: "Faroe Islands",
    code: "+298",
    flag: "fo",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "211234",
  },
  {
    name: "Fiji",
    code: "+679",
    flag: "fj",
    regex: "^\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "7012 3456",
  },
  {
    name: "Finland",
    code: "+358",
    flag: "fi",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3,4}$",
    placeholder: "041 2345678",
  },
  {
    name: "French Guiana",
    code: "+594",
    flag: "gf",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "0694 20 12 34",
  },
  {
    name: "French Polynesia",
    code: "+689",
    flag: "pf",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "87 12 34 56",
  },
  {
    name: "Gabon",
    code: "+241",
    flag: "ga",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "06 03 12 34",
  },
  {
    name: "Gambia",
    code: "+220",
    flag: "gm",
    regex: "^\\d{4}$",
    placeholder: "3012",
  },
  {
    name: "Georgia",
    code: "+995",
    flag: "ge",
    regex: "^\\d{3}[\\s\\-]?\\d{6}$",
    placeholder: "591 23 45 67",
  },
  {
    name: "Ghana",
    code: "+233",
    flag: "gh",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "023 123 4567",
  },
  {
    name: "Gibraltar",
    code: "+350",
    flag: "gi",
    regex: "^\\d{5}$",
    placeholder: "57123456",
  },
  {
    name: "Greece",
    code: "+30",
    flag: "gr",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "691 234 5678",
  },
  {
    name: "Greenland",
    code: "+299",
    flag: "gl",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "22 12 34",
  },
  {
    name: "Grenada",
    code: "+1",
    flag: "gd",
    regex: "^1[\\s\\-]?(473)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(473) 403-1234",
  },
  {
    name: "Guadeloupe",
    code: "+590",
    flag: "gp",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "0690 30 12 34",
  },
  {
    name: "Guam",
    code: "+1",
    flag: "gu",
    regex: "^1[\\s\\-]?(671)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(671) 300-1234",
  },
  {
    name: "Guatemala",
    code: "+502",
    flag: "gt",
    regex: "^\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "5123 4567",
  },
  {
    name: "Guernsey",
    code: "+44",
    flag: "gg",
    regex: "^\\d{4}[\\s\\-]?\\d{6}$",
    placeholder: "07781 123456",
  },
  {
    name: "Guinea",
    code: "+224",
    flag: "gn",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "601 12 34 56",
  },
  {
    name: "Guinea-Bissau",
    code: "+245",
    flag: "gw",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{2}$",
    placeholder: "955 123 456",
  },
  {
    name: "Guyana",
    code: "+592",
    flag: "gy",
    regex: "^\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "609 1234",
  },
  {
    name: "Haiti",
    code: "+509",
    flag: "ht",
    regex: "^\\d{3}[\\s\\-]?\\d{2}[\\s\\-]?\\d{4}$",
    placeholder: "34 10 1234",
  },
  {
    name: "Honduras",
    code: "+504",
    flag: "hn",
    regex: "^\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "9123 4567",
  },
  {
    name: "Hong Kong",
    code: "+852",
    flag: "hk",
    regex: "^\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "5123 4567",
  },
  {
    name: "Hungary",
    code: "+36",
    flag: "hu",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "20 123 4567",
  },
  {
    name: "Iceland",
    code: "+354",
    flag: "is",
    regex: "^\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "611 1234",
  },
  {
    name: "India",
    code: "+91",
    flag: "in",
    regex: "^\\d{5}[\\s\\-]?\\d{5}$",
    placeholder: "08123 45678",
  },
  {
    name: "Indonesia",
    code: "+62",
    flag: "id",
    regex: "^\\d{3}[\\s\\-]?\\d{3,4}[\\s\\-]?\\d{3,4}$",
    placeholder: "0812 3456 7890",
  },
  {
    name: "Iran",
    code: "+98",
    flag: "ir",
    regex: "^\\d{2}[\\s\\-]?\\d{4}[\\s\\-]?\\d{3}$",
    placeholder: "0912 345 6789",
  },
  {
    name: "Iraq",
    code: "+964",
    flag: "iq",
    regex: "^\\d{4}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "0791 234 5678",
  },
  {
    name: "Ireland",
    code: "+353",
    flag: "ie",
    regex: "^\\d{2}[\\s\\-]?\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "086 123 4567",
  },
  {
    name: "Isle of Man",
    code: "+44",
    flag: "im",
    regex: "^\\d{4}[\\s\\-]?\\d{6}$",
    placeholder: "07924 123456",
  },
  {
    name: "Israel",
    code: "+972",
    flag: "il",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "050 123 4567",
  },
  {
    name: "Jamaica",
    code: "+1",
    flag: "jm",
    regex: "^1[\\s\\-]?(876)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(876) 210-1234",
  },
  {
    name: "Japan",
    code: "+81",
    flag: "jp",
    regex: "^\\d{2}[\\s\\-]?\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "090-1234-5678",
  },
  {
    name: "Jersey",
    code: "+44",
    flag: "je",
    regex: "^\\d{4}[\\s\\-]?\\d{6}$",
    placeholder: "07797 712345",
  },
  {
    name: "Jordan",
    code: "+962",
    flag: "jo",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "07 9012 3456",
  },
  {
    name: "Kazakhstan",
    code: "+7",
    flag: "kz",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "0712 123 456",
  },
  {
    name: "Kenya",
    code: "+254",
    flag: "ke",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "0712 123456",
  },
  {
    name: "Kiribati",
    code: "+686",
    flag: "ki",
    regex: "^\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "7201234",
  },
  {
    name: "Kosovo",
    code: "+383",
    flag: "xk",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "044 123 456",
  },
  {
    name: "Kuwait",
    code: "+965",
    flag: "kw",
    regex: "^\\d{8}$",
    placeholder: "50012345",
  },
  {
    name: "Kyrgyzstan",
    code: "+996",
    flag: "kg",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "0700 123 456",
  },
  {
    name: "Laos",
    code: "+856",
    flag: "la",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3,4}$",
    placeholder: "020 23 456 789",
  },
  {
    name: "Latvia",
    code: "+371",
    flag: "lv",
    regex: "^\\d{2}[\\s\\-]?\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "21 234 567",
  },
  {
    name: "Lebanon",
    code: "+961",
    flag: "lb",
    regex: "^\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "71 123 456",
  },
  {
    name: "Lesotho",
    code: "+266",
    flag: "ls",
    regex: "^\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "5012 3456",
  },
  {
    name: "Liberia",
    code: "+231",
    flag: "lr",
    regex: "^\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "077 0123 456",
  },
  {
    name: "Libya",
    code: "+218",
    flag: "ly",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "091-2345678",
  },
  {
    name: "Liechtenstein",
    code: "+423",
    flag: "li",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "660 234 567",
  },
  {
    name: "Lithuania",
    code: "+370",
    flag: "lt",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{5}$",
    placeholder: "(8-612) 34567",
  },
  {
    name: "Luxembourg",
    code: "+352",
    flag: "lu",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "628 123 456",
  },
  {
    name: "Macau",
    code: "+853",
    flag: "mo",
    regex: "^\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "6612 3456",
  },
  {
    name: "Macedonia",
    code: "+389",
    flag: "mk",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "072 123 456",
  },
  {
    name: "Madagascar",
    code: "+261",
    flag: "mg",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{2}$",
    placeholder: "032 12 345 67",
  },
  {
    name: "Malawi",
    code: "+265",
    flag: "mw",
    regex: "^\\d{2}[\\s\\-]?\\d{5}$",
    placeholder: "0888 12 34 56",
  },
  {
    name: "Malaysia",
    code: "+60",
    flag: "my",
    regex: "^\\d{3}[\\s\\-]?\\d{7,9}$",
    placeholder: "012 345 6789",
  },
  {
    name: "Maldives",
    code: "+960",
    flag: "mv",
    regex: "^\\d{4,5}[\\s\\-]?\\d{4}$",
    placeholder: "771-2345",
  },
  {
    name: "Mali",
    code: "+223",
    flag: "ml",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "65 01 23 45",
  },
  {
    name: "Malta",
    code: "+356",
    flag: "mt",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3,4}$",
    placeholder: "9696 1234",
  },
  {
    name: "Marshall Islands",
    code: "+692",
    flag: "mh",
    regex: "^\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "235 1234",
  },
  {
    name: "Martinique",
    code: "+596",
    flag: "mq",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "0696 20 12 34",
  },
  {
    name: "Mauritania",
    code: "+222",
    flag: "mr",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "22 12 34 56",
  },
  {
    name: "Mauritius",
    code: "+230",
    flag: "mu",
    regex: "^\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "5251 2345",
  },
  {
    name: "Mayotte",
    code: "+262",
    flag: "yt",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "0639 01 23 45",
  },
  {
    name: "Mexico",
    code: "+52",
    flag: "mx",
    regex: "^\\d{2}[\\s\\-]?\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "55 1234 5678",
  },
  {
    name: "Micronesia",
    code: "+691",
    flag: "fm",
    regex: "^\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "350 1234",
  },
  {
    name: "Moldova",
    code: "+373",
    flag: "md",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3,4}$",
    placeholder: "0621 12 345",
  },
  {
    name: "Monaco",
    code: "+377",
    flag: "mc",
    regex: "^\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "6112 3456",
  },
  {
    name: "Mongolia",
    code: "+976",
    flag: "mn",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "8812 3456",
  },
  {
    name: "Montenegro",
    code: "+382",
    flag: "me",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3,4}$",
    placeholder: "067 622 345",
  },
  {
    name: "Montserrat",
    code: "+1",
    flag: "ms",
    regex: "^1[\\s\\-]?(664)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(664) 492-1234",
  },
  {
    name: "Morocco",
    code: "+212",
    flag: "ma",
    regex:
      "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{1}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "0650 123 456",
  },
  {
    name: "Mozambique",
    code: "+258",
    flag: "mz",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "82 301 2345",
  },
  {
    name: "Myanmar",
    code: "+95",
    flag: "mm",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{3}$",
    placeholder: "09 212 3456",
  },
  {
    name: "Namibia",
    code: "+264",
    flag: "na",
    regex: "^\\d{2}[\\s\\-]?\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "081 123 4567",
  },
  {
    name: "Nauru",
    code: "+674",
    flag: "nr",
    regex: "^\\d{3}$",
    placeholder: "555",
  },
  {
    name: "Nepal",
    code: "+977",
    flag: "np",
    regex: "^\\d{2}[\\s\\-]?\\d{9}$",
    placeholder: "984-1234567",
  },
  {
    name: "New Caledonia",
    code: "+687",
    flag: "nc",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "75 12 34",
  },
  {
    name: "New Zealand",
    code: "+64",
    flag: "nz",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "021 123 456",
  },
  {
    name: "Nicaragua",
    code: "+505",
    flag: "ni",
    regex: "^\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "8123 4567",
  },
  {
    name: "Niger",
    code: "+227",
    flag: "ne",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "90 12 34 56",
  },
  {
    name: "Nigeria",
    code: "+234",
    flag: "ng",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "0802 123 4567",
  },
  {
    name: "Niue",
    code: "+683",
    flag: "nu",
    regex: "^\\d{4}$",
    placeholder: "8888",
  },
  {
    name: "Norfolk Island",
    code: "+672",
    flag: "nf",
    regex: "^\\d{5}$",
    placeholder: "31900",
  },
  {
    name: "North Korea",
    code: "+850",
    flag: "kp",
    regex: "^\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "1921 1234",
  },
  {
    name: "Northern Mariana Islands",
    code: "+1",
    flag: "mp",
    regex: "^1[\\s\\-]?(670)?[\\s\\-]?\\d{4}$",
    placeholder: "(670) 234-5678",
  },
  {
    name: "Norway",
    code: "+47",
    flag: "no",
    regex: "^\\d{3}[\\s\\-]?\\d{2}[\\s\\-]?\\d{3}$",
    placeholder: "412 34 567",
  },
  {
    name: "Oman",
    code: "+968",
    flag: "om",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{3}$",
    placeholder: "9212 3456",
  },
  {
    name: "Pakistan",
    code: "+92",
    flag: "pk",
    regex: "^\\d{3}[\\s\\-]?\\d{7}$",
    placeholder: "0301 2345678",
  },
  {
    name: "Palau",
    code: "+680",
    flag: "pw",
    regex: "^\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "620 1234",
  },
  {
    name: "Palestine",
    code: "+970",
    flag: "ps",
    regex: "^\\d{3}[\\s\\-]?\\d{4,6}$",
    placeholder: "0599 123 456",
  },
  {
    name: "Panama",
    code: "+507",
    flag: "pa",
    regex: "^\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "6123 4567",
  },
  {
    name: "Papua New Guinea",
    code: "+675",
    flag: "pg",
    regex: "^\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "7012 3456",
  },
  {
    name: "Paraguay",
    code: "+595",
    flag: "py",
    regex: "^\\d{1}[\\s\\-]?\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "0961 234567",
  },
  {
    name: "Peru",
    code: "+51",
    flag: "pe",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "912 345 678",
  },
  {
    name: "Philippines",
    code: "+63",
    flag: "ph",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "0912 345 6789",
  },
  {
    name: "Poland",
    code: "+48",
    flag: "pl",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "512 345 678",
  },
  {
    name: "Puerto Rico",
    code: "+1",
    flag: "pr",
    regex: "^1[\\s\\-]?(787|939)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(787) 234-5678",
  },
  {
    name: "Qatar",
    code: "+974",
    flag: "qa",
    regex: "^\\d{3,4}[\\s\\-]?\\d{4}$",
    placeholder: "3312 3456",
  },
  {
    name: "Réunion",
    code: "+262",
    flag: "re",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "0692 12 34 56",
  },
  {
    name: "Romania",
    code: "+40",
    flag: "ro",
    regex: "^\\d{4}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "0712 345 678",
  },
  {
    name: "Russia",
    code: "+7",
    flag: "ru",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "812 345 67 89",
  },
  {
    name: "Rwanda",
    code: "+250",
    flag: "rw",
    regex: "^\\d{3}$",
    placeholder: "250",
  },
  {
    name: "Saint Barthélemy",
    code: "+590",
    flag: "bl",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "0690 30 12 34",
  },
  {
    name: "Saint Helena",
    code: "+290",
    flag: "sh",
    regex: "^\\d{4}$",
    placeholder: "5123",
  },
  {
    name: "Saint Kitts and Nevis",
    code: "+1",
    flag: "kn",
    regex: "^1[\\s\\-]?(869)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(869) 765-2917",
  },
  {
    name: "Saint Lucia",
    code: "+1",
    flag: "lc",
    regex: "^1[\\s\\-]?(758)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(758) 284-5678",
  },
  {
    name: "Saint Martin",
    code: "+590",
    flag: "mf",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "0690 30 12 34",
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "+508",
    flag: "pm",
    regex: "^\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "055 12 34",
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "+1",
    flag: "vc",
    regex: "^1[\\s\\-]?(784)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(784) 430-1234",
  },
  {
    name: "Samoa",
    code: "+685",
    flag: "ws",
    regex: "^\\d{2}[\\s\\-]?\\d{4}$",
    placeholder: "72123",
  },
  {
    name: "San Marino",
    code: "+378",
    flag: "sm",
    regex: "^\\d{2}[\\s\\-]?\\d{6}$",
    placeholder: "66 66 12 34",
  },
  {
    name: "São Tomé and Príncipe",
    code: "+239",
    flag: "st",
    regex: "^\\d{7}$",
    placeholder: "981 2345",
  },
  {
    name: "Saudi Arabia",
    code: "+966",
    flag: "sa",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "051 234 5678",
  },
  {
    name: "Senegal",
    code: "+221",
    flag: "sn",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "70 123 4567",
  },
  {
    name: "Serbia",
    code: "+381",
    flag: "rs",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3,4}$",
    placeholder: "060 1234567",
  },
  {
    name: "Seychelles",
    code: "+248",
    flag: "sc",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "2 510 123",
  },
  {
    name: "Sierra Leone",
    code: "+232",
    flag: "sl",
    regex: "^\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "076 123456",
  },
  {
    name: "Singapore",
    code: "+65",
    flag: "sg",
    regex: "^\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "8123 4567",
  },
  {
    name: "Sint Maarten",
    code: "+1",
    flag: "sx",
    regex: "^1[\\s\\-]?(721)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(721) 520-5678",
  },
  {
    name: "Slovakia",
    code: "+421",
    flag: "sk",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "0912 123 456",
  },
  {
    name: "Slovenia",
    code: "+386",
    flag: "si",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "031 234 567",
  },
  {
    name: "Solomon Islands",
    code: "+677",
    flag: "sb",
    regex: "^\\d{3}$",
    placeholder: "74 123",
  },
  {
    name: "Somalia",
    code: "+252",
    flag: "so",
    regex: "^\\d{5}$",
    placeholder: "90712",
  },
  {
    name: "South Africa",
    code: "+27",
    flag: "za",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "071 123 4567",
  },
  {
    name: "South Korea",
    code: "+82",
    flag: "kr",
    regex: "^\\d{2}[\\s\\-]?\\d{3,4}[\\s\\-]?\\d{4}$",
    placeholder: "010-1234-5678",
  },
  {
    name: "South Sudan",
    code: "+211",
    flag: "ss",
    regex: "^\\d{3}$",
    placeholder: "0911",
  },
  {
    name: "Sri Lanka",
    code: "+94",
    flag: "lk",
    regex: "^\\d{3}[\\s\\-]?\\d{7}$",
    placeholder: "071 234 5678",
  },
  {
    name: "Sudan",
    code: "+249",
    flag: "sd",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "091 123 1234",
  },
  {
    name: "Suriname",
    code: "+597",
    flag: "sr",
    regex: "^\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "741-2345",
  },
  {
    name: "Svalbard and Jan Mayen",
    code: "+47",
    flag: "sj",
    regex: "^\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "412 34 567",
  },
  {
    name: "Swaziland",
    code: "+268",
    flag: "sz",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "7612 3456",
  },
  {
    name: "Switzerland",
    code: "+41",
    flag: "ch",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "078 123 45 67",
  },
  {
    name: "Syria",
    code: "+963",
    flag: "sy",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "0944 567 890",
  },
  {
    name: "Taiwan",
    code: "+886",
    flag: "tw",
    regex: "^\\d{2}[\\s\\-]?\\d{3,4}[\\s\\-]?\\d{4}$",
    placeholder: "0912 345 678",
  },
  {
    name: "Tajikistan",
    code: "+992",
    flag: "tj",
    regex: "^\\d{3}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "917 12 3456",
  },
  {
    name: "Tanzania",
    code: "+255",
    flag: "tz",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "0621 123 456",
  },
  {
    name: "Thailand",
    code: "+66",
    flag: "th",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "08 1234 5678",
  },
  {
    name: "Timor-Leste",
    code: "+670",
    flag: "tl",
    regex: "^\\d{3}$",
    placeholder: "7721 2345",
  },
  {
    name: "Togo",
    code: "+228",
    flag: "tg",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "90 11 23 45",
  },
  {
    name: "Tokelau",
    code: "+690",
    flag: "tk",
    regex: "^\\d{4}$",
    placeholder: "7290",
  },
  {
    name: "Tonga",
    code: "+676",
    flag: "to",
    regex: "^\\d{2}[\\s\\-]?\\d{3}$",
    placeholder: "771 5123",
  },
  {
    name: "Trinidad and Tobago",
    code: "+1",
    flag: "tt",
    regex: "^1[\\s\\-]?(868)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(868) 291-1234",
  },
  {
    name: "Tunisia",
    code: "+216",
    flag: "tn",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{3}$",
    placeholder: "20 123 456",
  },
  {
    name: "Turkey",
    code: "+90",
    flag: "tr",
    regex: "^\\d{3}[\\s\\-]?\\d{3}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "0501 234 5678",
  },
  {
    name: "Turkmenistan",
    code: "+993",
    flag: "tm",
    regex: "^\\d{3}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "8 66 123456",
  },
  {
    name: "Turks and Caicos Islands",
    code: "+1",
    flag: "tc",
    regex: "^1[\\s\\-]?(649)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(649) 231-1234",
  },
  {
    name: "Tuvalu",
    code: "+688",
    flag: "tv",
    regex: "^\\d{3}$",
    placeholder: "900",
  },
  {
    name: "U.S. Virgin Islands",
    code: "+1",
    flag: "vi",
    regex: "^1[\\s\\-]?(340)?[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "(340) 642-1234",
  },
  {
    name: "Uganda",
    code: "+256",
    flag: "ug",
    regex: "^\\d{3}[\\s\\-]?\\d{6,7}$",
    placeholder: "0712 345678",
  },
  {
    name: "Ukraine",
    code: "+380",
    flag: "ua",
    regex: "^\\d{3}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "050 123 4567",
  },
  {
    name: "United Arab Emirates",
    code: "+971",
    flag: "ae",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "050 123 4567",
  },
  {
    name: "Uruguay",
    code: "+598",
    flag: "uy",
    regex: "^\\d{2}[\\s\\-]?\\d{4}[\\s\\-]?\\d{4}$",
    placeholder: "094 231 234",
  },
  {
    name: "Uzbekistan",
    code: "+998",
    flag: "uz",
    regex: "^\\d{3}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "8 91 234 56 78",
  },
  {
    name: "Vanuatu",
    code: "+678",
    flag: "vu",
    regex: "^\\d{3}$",
    placeholder: "591 2345",
  },
  {
    name: "Vatican City",
    code: "+379",
    flag: "va",
    regex: "^\\d{2}[\\s\\-]?\\d{8}$",
    placeholder: "312 12345678",
  },
  {
    name: "Venezuela",
    code: "+58",
    flag: "ve",
    regex: "^\\d{4}[\\s\\-]?\\d{6}$",
    placeholder: "0412 123 4567",
  },
  {
    name: "Vietnam",
    code: "+84",
    flag: "vn",
    regex: "^\\d{3}[\\s\\-]?\\d{3,4}[\\s\\-]?\\d{4}$",
    placeholder: "091 234 5678",
  },
  {
    name: "Wallis and Futuna",
    code: "+681",
    flag: "wf",
    regex: "^\\d{2}[\\s\\-]?\\d{2}[\\s\\-]?\\d{2}$",
    placeholder: "50 12 34",
  },
  {
    name: "Yemen",
    code: "+967",
    flag: "ye",
    regex: "^\\d{3}[\\s\\-]?\\d{7}$",
    placeholder: "0712 345 678",
  },
  {
    name: "Zambia",
    code: "+260",
    flag: "zm",
    regex: "^\\d{2}[\\s\\-]?\\d{3}[\\s\\-]?\\d{4}$",
    placeholder: "095 5123456",
  },
  {
    name: "Zimbabwe",
    code: "+263",
    flag: "zw",
    regex: "^\\d{3}$",
    placeholder: "071 234",
  },
];

import SvgWrapper from "./SvgWrapper";
import BodySvgFront from "./BodySvgFront";
import BodySvgBack from "./BodySvgBack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconPencilOutline } from "./Icons";

export default function BodyDiagram({
  valueFront,
  valueBack,
  onChangeFront,
  onChangeBack,
  valueAvoidFront,
  onChangeAvoidFront,
  valueAvoidBack,
  onChangeAvoidBack,
}: any) {
  const { t } = useTranslation();
  const [focus, setFocus] = useState(true);

  const onPartClick = (name: string) => {
    const indexFront = valueFront.indexOf(name);
    const indexAvoidFront = valueAvoidFront.indexOf(name);

    if (indexFront !== -1) {
      const newFront = [...valueFront];
      newFront.splice(indexFront, 1);
      onChangeFront(newFront);
    } else if (indexAvoidFront !== -1) {
      const newAvoidFront = [...valueAvoidFront];
      newAvoidFront.splice(indexAvoidFront, 1);
      onChangeAvoidFront(newAvoidFront);
    } else {
      if (focus) {
        onChangeFront([...valueFront, name]);
      } else {
        onChangeAvoidFront([...valueAvoidFront, name]);
      }
    }
  };

  const onPartClickBack = (name: string) => {
    const indexBack = valueBack.indexOf(name);
    const indexAvoidBack = valueAvoidBack.indexOf(name);

    if (indexBack !== -1) {
      const newBack = [...valueBack];
      newBack.splice(indexBack, 1);
      onChangeBack(newBack);
    } else if (indexAvoidBack !== -1) {
      const newAvoidBack = [...valueAvoidBack];
      newAvoidBack.splice(indexAvoidBack, 1);
      onChangeAvoidBack(newAvoidBack);
    } else {
      if (focus) {
        onChangeBack([...valueBack, name]);
      } else {
        onChangeAvoidBack([...valueAvoidBack, name]);
      }
    }
  };

  return (
    <SvgWrapper>
      <div className="flex items-center justify-around">
        <button
          className={`flex items-start p-2 min-w-40 ${
            focus ? "rounded-full bg-[#D1E8E7] shadow-md" : ""
          }`}
          onClick={() => setFocus(true)}
        >
          <div className="h-8 w-8 rounded-full bg-[#529A98] mr-2 flex items-center justify-center">
            {focus && <IconPencilOutline className="h-5 w-5" color="#FFF" />}
          </div>
          <div>
            <div className="flex items-center">
              {t("default.focus")}
              <div className="h-2 w-2 rounded-full bg-[#529A98] ml-1" />
            </div>
            <p className="text-xs text-gray-600 -mt-2 truncate">
              {t("default.focus_description")}
            </p>
          </div>
        </button>
        <button
          className={`flex items-center p-2 min-w-40 ${
            !focus ? "rounded-full bg-[#FDE2E2] shadow-md" : ""
          }`}
          onClick={() => setFocus(false)}
        >
          <div className="h-8 w-8 rounded-full bg-[#fca5a5] mr-2 flex items-center justify-center">
            {!focus && <IconPencilOutline className="h-5 w-5" color="#FFF" />}
          </div>
          <div>
            <div className="flex items-center">
              {t("default.avoid")}
              <div className="h-2 w-2 rounded-full bg-[#fca5a5] ml-1" />
            </div>
            <p className="text-xs text-gray-600 -mt-2 truncate">
              {t("default.avoid_description")}
            </p>
          </div>
        </button>
      </div>
      <p className="font-bold text-gray-700 text-center text-xl mt-6 mb-2">
        {t("default.body_front")}
      </p>
      <BodySvgFront
        selected={valueFront}
        selectedAvoid={valueAvoidFront}
        onPartClick={onPartClick}
      />
      <div className="flex items-center justify-around pt-20">
        <button
          className={`flex items-start p-2 min-w-40 ${
            focus ? "rounded-full bg-[#D1E8E7] shadow-md" : ""
          }`}
          onClick={() => setFocus(true)}
        >
          <div className="h-8 w-8 rounded-full bg-[#529A98] mr-2 flex items-center justify-center">
            {focus && <IconPencilOutline className="h-5 w-5" color="#FFF" />}
          </div>
          <div>
            <div className="flex items-center">
              {t("default.focus")}
              <div className="h-2 w-2 rounded-full bg-[#529A98] ml-1" />
            </div>
            <p className="text-xs text-gray-600 -mt-2 truncate">
              {t("default.focus_description")}
            </p>
          </div>
        </button>
        <button
          className={`flex items-center p-2 min-w-40 ${
            !focus ? "rounded-full bg-[#FDE2E2] shadow-md" : ""
          }`}
          onClick={() => setFocus(false)}
        >
          <div className="h-8 w-8 rounded-full bg-[#fca5a5] mr-2 flex items-center justify-center">
            {!focus && <IconPencilOutline className="h-5 w-5" color="#FFF" />}
          </div>
          <div>
            <div className="flex items-center">
              {t("default.avoid")}
              <div className="h-2 w-2 rounded-full bg-[#fca5a5] ml-1" />
            </div>
            <p className="text-xs text-gray-600 -mt-2 truncate">
              {t("default.avoid_description")}
            </p>
          </div>
        </button>
      </div>
      <p className="font-bold text-gray-700 text-center text-xl mt-6 mb-3">
        {t("default.body_back")}
      </p>
      <BodySvgBack
        selected={valueBack}
        selectedAvoid={valueAvoidBack}
        onPartClick={onPartClickBack}
      />
    </SvgWrapper>
  );
}

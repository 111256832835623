import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMassage } from "../provider/massage";
import { IconClose } from "../components/Icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

function Cancelling() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const methods = useForm();
  const { handleSubmit, register } = methods;
  const {
    setStep,
    setMassageRequest,
    setShowPopupClose,
    cancelMassage,
    massageRequest,
  } = useMassage();
  const [activeDetails, setActiveDetails] = useState(false);

  const onSubmit = useCallback(
    async (e: any) => {
      try {
        await cancelMassage({
          ...massageRequest!,
          ...e
        });

        setMassageRequest(undefined);
        setStep(1);
        setShowPopupClose(false);
        setActiveDetails(false);

        return navigate("/");
      } catch { }
    },
    [
      massageRequest,
      navigate,
      setMassageRequest,
      setShowPopupClose,
      setStep,
      cancelMassage
    ]
  );

  const handleClosePopup = () => {
    setShowPopupClose(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full backdrop-blur-sm bg-black/30"
    >
      <div className="relative p-4 w-full max-w-md max-h-full flex items-center justify-center">
        <div className="relative bg-white rounded-lg shadow flex">
          <button
            onClick={handleClosePopup}
            className="absolute top-3 end-2.5 text-gray-700 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
          >
            <IconClose />
          </button>
          <div className="p-4 md:p-5 text-center">
            <div className="max-w-md w-full space-y-8 p-10 bg-white rounded-xl ">
              <div>
                <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
                  {t("default.please_select_a_motive")}
                </h2>
              </div>
              <FormProvider {...methods}>
                <form
                  onSubmit={handleSubmit((e) => onSubmit(e))}
                  className="mt-8 space-y-6"
                >
                  <div className="rounded-md shadow-sm -space-y-px">
                    <div className="flex flex-col items-start mt-4">
                      <label className="font-bold text-gray-700">
                        {t("default.motive")}*
                      </label>
                      <select
                        {...register("motive", { required: true })}
                        required
                        onChange={() => setActiveDetails(true)}
                        className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                      >
                        <option value="">{t("default.select")}</option>
                        <option value="Store">Store</option>
                        <option value="Customer">Customer</option>
                      </select>
                    </div>
                  </div>

                  {activeDetails && (
                    <div className="flex flex-col items-start justify-center w-full">
                      <label className="font-bold text-gray-700">
                        {t("default.details")}*
                      </label>
                      <textarea
                        {...register("details", { required: true })}
                        required
                        className="flex-1 min-h-20 appearance-none rounded-md relative w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                      />
                    </div>
                  )}

                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#235B66] hover:bg-[#1E4F5B] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mt-6"
                    >
                      {t("default.submit")}
                    </button>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Cancelling;
